import LazyLoader from "@components-core/LazyLoader";
import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  //
  userOrderDetails,
  userOrderDetailsFailure,
  userOrderDetailsSuccess,
  userOrderList,
  userOrderListFailure,
  userOrderListSuccess,
  //
  userOrderRemove,
  userOrderRemoveFailure,
  userOrderRemoveSuccess
} from "@redux-actions/user";
import { UserOrderListBS } from "@style-variables";
import { actualDeviceIs } from "@utils/breakpoints";
import { timestampToYmd } from "@utils/date";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import RemoveUserOrderModal from "./modals/RemoveOrder";
import ViewUserOrderModal from "./modals/ViewOrder";

// TODO: extend DataGrid instead PureComponent
class UserOrderList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { removeOrder: null, viewOrder: null, orderDetails: null };
  }

  componentDidMount() {
    // fetch the user orders list
    if (this.props.security.enabled && this.props.loggedUser) {
      this.props
        .userOrderList(
          { userId: this.props.loggedUser.userId },
          this.props.siteConfig
        )
        .then(result => {
          this.props.userOrderListSuccess(result);
        })
        .catch(this.props.userOrderListFailure);
    }
  }

  fetchOrderLines(order) {
    // fetch the user orders list
    if (this.props.security.enabled && this.props.loggedUser) {
      this.props
        .userOrderDetails(
          { userId: this.props.loggedUser.userId, orderId: order.orderId },
          this.props.siteConfig
        )
        .then(result => {
          this.props.userOrderDetailsSuccess(result);
        })
        .catch(this.props.userOrderDetailsFailure);
    }
  }

  removeUserOrder(order) {
    // fetch the user orders list
    if (this.props.security.enabled && this.props.loggedUser) {
      this.props
        .userOrderRemove(
          { userId: this.props.loggedUser.userId, orderId: order.orderId },
          this.props.siteConfig
        )
        .then(result => {
          this.props.userOrderRemoveSuccess(result);
          this.setState({ removeOrder: null });
        })
        .catch(error => {
          this.props.userOrderRemoveFailure(error);
          this.setState({ removeOrder: { order, error } });
        });
    }
  }

  /**
   * @description Format the order status by status
   * @param {String} status The order status
   * @returns {Strimg|JSX}
   * @memberof UserOrderList
   * @see UserOrder.gql :: OrderStatus
   */
  getOrderStatus(status) {
    const i18n = this.props.i18n.components.UserOrderList.ORDER_STATUS;

    switch (status) {
      case "CREATED":
      case "UNPROCESSED":
      case "DELIVERY_READY":
      case "ORDERED":
        return status;
      case "PAYMENT_PENDING":
        return <Badge variant="warning">{i18n[status]}</Badge>;
      case "PROCESSED":
        return <Badge variant="info">{i18n[status]}</Badge>;
      case "DELIVERED":
        return <Badge variant="success">{i18n[status]}</Badge>;
      default:
        return <Badge variant="danger">{i18n[status]}</Badge>;
    }
  }

  /**
   * @description Format the order status by status
   * @param {String} status The payment status
   * @returns {String|JSX}
   * @memberof UserOrderList
   * @see UserOrder.gql :: PaymentStatus
   */
  getPaymentStatus(status) {
    const i18n = this.props.i18n.components.UserOrderList.PAYMENT_STATUS;

    switch (status) {
      case "UNPAYED":
      case "PARTIAL_PAYED":
        return <Badge variant="warning">{i18n[status]}</Badge>;
      case "PAYED":
      case "PAYMENT_RECEIVED":
        return <Badge variant="success">{i18n[status]}</Badge>;
      default:
        return <Badge variant="danger">{i18n[status]}</Badge>;
    }
  }

  getActionButtons(order) {
    return [
      order.canView
        ? {
            icon: "eye",
            className: "can-view",
            onClick: e => {
              this.fetchOrderLines(order);
              this.setState({ viewOrder: order });
            }
          }
        : null,
      order.canRemove
        ? {
            icon: "trash-alt",
            className: "can-remove",
            onClick: e => this.setState({ removeOrder: order })
          }
        : null
      //order.canPrint ? { icon: "print", className: "can-print" } : null
    ]
      .filter(Boolean)
      .map((item, i) => (
        <FontAwesomeIcon
          icon={item.icon}
          color={item.color}
          key={i}
          className={joinNonEmptyStrings(item.className, "mx-1", " ")}
          onClick={item.onClick}
        />
      ));
  }

  renderHeader(currencyCode, i18n) {
    return (
      <Row>
        <Col>#</Col>
        <Col>{i18n.COL_ORDER_NO}</Col>
        <Col>{i18n.COL_ORDER_DATE}</Col>
        <Col>Items count</Col>
        <Col>
          {i18n.COL_ORDER_VALUE.replace(
            "%currencyCode%",
            this.props.currencyCode || currencyCode || "?"
          )}
        </Col>
        <Col>{i18n.COL_ORDER_STATUS}</Col>
        <Col>{i18n.COL_PAYMENT_STATUS}</Col>
        <Col></Col>
      </Row>
    );
  }

  renderOrderList(orderList, onOrder) {
    return orderList.rows.map((row, index) => {
      onOrder(row);

      const actionButtons = this.getActionButtons(row);

      return (
        <Row key={index}>
          <Col>{index + 1}</Col>
          <Col>{row.orderNo}</Col>
          <Col>{timestampToYmd(+row.orderDate)}</Col>
          <Col>{row.itemsCount}</Col>
          <Col>
            {[
              row.currencyPrefix,
              (+row.orderValue).toLocaleString(),
              row.currencySuffix
            ].filter(Boolean)}
          </Col>
          <Col>{this.getOrderStatus(row.orderStatus)}</Col>
          <Col>{this.getPaymentStatus(row.paymentStatus)}</Col>
          <Col>{actionButtons}</Col>
        </Row>
      );
    });
  }

  renderFooter(
    orderValueGrandTotal,
    orderCount,
    currencyPrefix,
    currencySuffix
  ) {
    if (!orderCount) {
      return null;
    }

    return (
      <Row>
        <Col>{orderCount} orders</Col>
        <Col />
        <Col />
        <Col>
          {[
            currencyPrefix,
            orderValueGrandTotal.toLocaleString(),
            currencySuffix
          ].filter(Boolean)}
        </Col>
        <Col />
        <Col />
        <Col />
      </Row>
    );
  }

  renderRemoveOrderModal() {
    if (!this.state.removeOrder) {
      return null;
    }

    return (
      <RemoveUserOrderModal
        show={Boolean(this.state.removeOrder)}
        canRemove={this.state.removeOrder && !this.state.removeOrder.error}
        order={this.state.removeOrder}
        onClose={(e, order) => this.setState({ removeOrder: null })}
        onRemove={(e, order) => this.removeUserOrder(order)}
        orderStatusTransformer={status => this.getOrderStatus(status)}
        paymentStatusTransformer={status => this.getPaymentStatus(status)}
      />
    );
  }

  renderViewOrderModal() {
    if (!this.state.viewOrder) {
      return null;
    }

    return (
      <ViewUserOrderModal
        show={Boolean(this.state.viewOrder)}
        order={{
          ...this.state.viewOrder,
          orderDetails: this.props.orderDetails
        }}
        onClose={(e, order) => this.setState({ viewOrder: null })}
        onPrint={(e, order) => {}}
        orderStatusTransformer={status => this.getOrderStatus(status)}
        paymentStatusTransformer={status => this.getPaymentStatus(status)}
        centered={actualDeviceIs.portrait && !actualDeviceIs.tablet}
      />
    );
  }

  render() {
    const i18n = this.props.i18n.components.UserOrderList;

    let rows = null;
    let orderCount = 0;
    let orderValueGrandTotal = 0;
    let currencyCode = null;
    let currencyPrefix = null;
    let currencySuffix = null;

    const orderList = this.props.orderList;

    if (orderList.loading) {
      rows = (
        <Row className="loading">
          <Col>{i18n.FETCHING}</Col>
        </Row>
      );
    } else if (orderList.error) {
      const errMsg = i18n.ERRORS[orderList.error] || orderList.error;

      rows = (
        <Row className="error">
          <Col>{errMsg}</Col>
        </Row>
      );
    } else if (Array.isArray(orderList.rows) && orderList.rows.length) {
      orderCount = orderList.rows.length;
      currencyCode = orderList.rows[0].currencyCode;
      currencyPrefix = orderList.rows[0].currencyPrefix;
      currencySuffix = orderList.rows[0].currencySuffix;

      rows = this.renderOrderList(
        orderList,
        order => (orderValueGrandTotal += +order.orderValue)
      );
    } else {
      rows = (
        <Row className="no-data">
          <Col>{i18n.DATA_NOT_FOUND}</Col>
        </Row>
      );
    }

    const modals = [this.renderRemoveOrderModal(), this.renderViewOrderModal()]
      .filter(Boolean)
      .map((modal, key) => ({ ...modal, key }));

    const header = this.renderHeader(currencyCode, i18n);
    const footer = this.renderFooter(
      orderValueGrandTotal,
      orderCount,
      currencyPrefix,
      currencySuffix
    );

    return (
      <React.Fragment>
        <h4>{i18n.TITLE}</h4>
        <Container
          fluid
          className={getComponentClassName(
            this.props.className,
            null,
            "my-3 border"
          )}
        >
          {header}
          <LazyLoader fetched={!orderList.loading} thumbnail={false}>
            {rows}
          </LazyLoader>
          {footer}
        </Container>
        {modals}
      </React.Fragment>
    );
  }
}

UserOrderList.propTypes = {
  className: PropTypes.string
};

UserOrderList.defaultProps = {
  className: UserOrderListBS
};

UserOrderList.mapDispatchToProps = {
  userOrderList,
  userOrderListSuccess,
  userOrderListFailure,
  //
  userOrderDetails,
  userOrderDetailsFailure,
  userOrderDetailsSuccess,
  //
  userOrderRemove,
  userOrderRemoveFailure,
  userOrderRemoveSuccess
};

UserOrderList.mapStateToProps = (state, ownProps) => {
  return {
    orderList: {
      error: state.userOrderList.error,
      loading: state.userOrderList.isFetching,
      rows: state.userOrderList.status
        ? state.userOrderList.status.userOrderList
        : null
    },
    orderDetails: {
      error: state.userOrderDetails.error,
      loading: state.userOrderDetails.isFetching,
      header: state.userOrderDetails.status
        ? state.userOrderDetails.status.userOrderDetails.header
        : null,
      lines: state.userOrderDetails.status
        ? state.userOrderDetails.status.userOrderDetails.lines
        : null
    }
  };
};

export default connectHOCs(UserOrderList, {
  withRouter: true,
  withSite: true,
  withConnect: true,
  withGraphQL: true
});
