import LazyLoader from "@components-core/LazyLoader";
import PureComponent from "@components-core/PureComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withGraphQLContext } from "@graphql-context";
import {
  userPaymentList,
  userPaymentListFailure,
  userPaymentListSuccess
} from "@redux-actions/user";
import { withSiteContext } from "@site-context";
import { UserPaymentListBS } from "@style-variables";
import { timestampToYmd } from "@utils/date";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class UserPaymentList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    // fetch the user orders list
    if (this.props.security.enabled && this.props.loggedUser) {
      this.props
        .userPaymentList(
          { userId: this.props.loggedUser.userId },
          this.props.siteConfig
        )
        .then(result => {
          this.props.userPaymentListSuccess(result);
        })
        .catch(this.props.userPaymentListFailure);
    }
  }

  getActionButtons(order) {
    const dummy = false;
    return [
      dummy
        ? {
            icon: "eye",
            className: "can-view",
            onClick: e => {
              this.fetchOrderLines(order);
              this.setState({ viewOrder: order });
            }
          }
        : null
    ]
      .filter(Boolean)
      .map((item, i) => (
        <FontAwesomeIcon
          icon={item.icon}
          color={item.color}
          key={i}
          className={joinNonEmptyStrings(item.className, "mx-1", " ")}
          onClick={item.onClick}
        />
      ));
  }

  renderHeader(currencyCode, i18n) {
    return (
      <Row>
        <Col>#</Col>
        <Col>{i18n.COL_PAYMENT_NO}</Col>
        <Col>{i18n.COL_ORDER_NO}</Col>
        <Col>{i18n.COL_PAYMENT_DATE}</Col>
        <Col>
          {i18n.COL_PAYMENT_VALUE.replace(
            "%currencyCode%",
            this.props.currencyCode || currencyCode || "?"
          )}
        </Col>
        <Col>{i18n.COL_PAYMENT_TYPE}</Col>
        <Col></Col>
      </Row>
    );
  }

  renderPaymentList(paymentList, onPayment) {
    return paymentList.rows.map((row, index) => {
      onPayment(row);

      const actionButtons = this.getActionButtons(row);

      return (
        <Row key={index}>
          <Col>{index + 1}</Col>
          <Col>{row.paymentId}</Col>
          <Col>{row.orderId}</Col>
          <Col>{timestampToYmd(+(row.paymentDate || row.transactionDate))}</Col>
          <Col>
            {[
              row.currencyPrefix,
              (+row.paidAmount).toLocaleString(),
              row.currencySuffix
            ].filter(Boolean)}
          </Col>
          <Col>{row.batchType || row.paymentType}</Col>
          <Col>{actionButtons}</Col>
        </Row>
      );
    });
  }

  renderFooter(
    paidAmountGrandTotal,
    paymentCount,
    currencyPrefix,
    currencySuffix
  ) {
    if (!paymentCount) {
      return null;
    }

    return (
      <Row>
        <Col>{paymentCount} payments</Col>
        <Col />
        <Col />
        <Col />
        <Col>
          {[
            currencyPrefix,
            paidAmountGrandTotal.toLocaleString(),
            currencySuffix
          ].filter(Boolean)}
        </Col>
        <Col />
        <Col />
      </Row>
    );
  }

  render() {
    const i18n = this.props.i18n.components.UserPaymentList;

    let rows = null;
    let paymentCount = 0;
    let paidAmountGrandTotal = 0;
    let currencyCode = null;
    let currencyPrefix = null;
    let currencySuffix = null;

    const paymentList = this.props.paymentList;

    if (paymentList.loading) {
      rows = (
        <Row className="loading">
          <Col>{i18n.FETCHING}</Col>
        </Row>
      );
    } else if (paymentList.error) {
      const errMsg = i18n.ERRORS[paymentList.error] || paymentList.error;

      rows = (
        <Row className="error">
          <Col>{errMsg}</Col>
        </Row>
      );
    } else if (Array.isArray(paymentList.rows) && paymentList.rows.length) {
      paymentCount = paymentList.rows.length;
      currencyCode = paymentList.rows[0].currencyCode;
      currencyPrefix = paymentList.rows[0].currencyPrefix;
      currencySuffix = paymentList.rows[0].currencySuffix;

      rows = this.renderPaymentList(
        paymentList,
        payment => (paidAmountGrandTotal += +payment.paidAmount)
      );
    } else {
      rows = (
        <Row className="no-data">
          <Col>{i18n.DATA_NOT_FOUND}</Col>
        </Row>
      );
    }

    const modals = [].filter(Boolean).map((modal, key) => ({ ...modal, key }));

    const header = this.renderHeader(currencyCode, i18n);
    const footer = this.renderFooter(
      paidAmountGrandTotal,
      paymentCount,
      currencyPrefix,
      currencySuffix
    );

    return (
      <React.Fragment>
        <h4>{i18n.TITLE}</h4>
        <Container
          fluid
          className={getComponentClassName(
            this.props.className,
            null,
            "my-3 border"
          )}
        >
          {header}
          <LazyLoader fetched={!paymentList.loading} thumbnail={false}>
            {rows}
          </LazyLoader>
          {footer}
        </Container>
        {modals}
      </React.Fragment>
    );
  }
}

UserPaymentList.propTypes = {
  className: PropTypes.string
};

UserPaymentList.defaultProps = {
  className: UserPaymentListBS
};

const mapDispatchToProps = {
  userPaymentList,
  userPaymentListSuccess,
  userPaymentListFailure
};

const mapStateToProps = (state, ownProps) => {
  return {
    paymentList: {
      error: state.userPaymentList.error,
      loading: state.userPaymentList.isFetching,
      rows: state.userPaymentList.status
        ? state.userPaymentList.status.userPaymentList
        : null
    }
  };
};

const WithReduxUserPaymentList = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPaymentList);

const WithSiteUserPaymentList = props =>
  withSiteContext(WithReduxUserPaymentList)(props);

const WithRouterUserPaymentList = props =>
  withRouter(WithSiteUserPaymentList)(props);

export default props => withGraphQLContext(WithRouterUserPaymentList)(props);
