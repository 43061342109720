import { PAGE_KEY_LOGIN } from "@constants";
import { UserLoginBS } from "@style-variables";
import { mergeTemplateItems } from "../common/utils";
import { createLazyTemplateItem } from "../utils";

const TEMPLATE_KEY = "user/LoginTemplate";

const UserLoginTemplate = props => {
  const { reCaptchaKey } = props;

  const defaultTemplate = [
    {
      component: "User/Login",
      props: {
        className: "bg-light " + UserLoginBS,
        reCaptcha: {
          disabled: !reCaptchaKey,
          siteKey: reCaptchaKey
        }
      }
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

UserLoginTemplate.mapValueToProps = value => ({
  reCaptchaKey: value.googleReCaptcha
});

UserLoginTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_LOGIN,
  templateKey: TEMPLATE_KEY
};

export default UserLoginTemplate;
