import { PAGE_KEY_HOME } from "@constants";
import { mergeTemplateItems } from "../common/utils";
import { createLazyTemplateItem } from "../utils";

const TEMPLATE_KEY = "sites/HomeTemplate-Beer";

const HomeTemplate = props => {
  const defaultTemplate = [
    {
      component: "Promotion/Promotion",
      props: props.campaign
    },
    {
      component: "Brands/Info",
      props: props.productBrandInfo
    },
    {
      component: "Brands/SiteLogoList",
      props: props.productBrand
    },
    {
      component: "ProductSerie/SiteList",
      props: { horizontal: true }
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

HomeTemplate.mapValueToProps = value => ({
  homePage: value.homePage,
  campaign: value.campaign,
  productBrandInfo: value.productBrandInfo,
  imageFootnoteCardDeck: value.imageFootnoteCardDeck,
  searchBanner: value.searchBanner,
  productBrand: value.productBrand
});

HomeTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_HOME,
  templateKey: TEMPLATE_KEY
};

export default HomeTemplate;
