import { PAGE_KEY_USER_PROFILE } from "@constants";
import { UserProfilePageBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";

export default ({ ssiteId, i18n, graphql, screen, imgParams, siteContext }) => {
  const pageId = UserProfilePageBS;

  const defaultHelmet = {
    title: "user-profile-page",
    meta: {
      name: {
        description: "user profile page",
        // keywords: "x,y,z",
        robots: "noindex"
      }
    }
  };

  const helmet = mergeHelmet(
    mergeHelmet(defaultHelmet, siteContext.helmet, screen),
    screen.helmet,
    screen
  );

  return {
    [PAGE_KEY_USER_PROFILE]: {
      id: pageId,
      helmet,
      className: getComponentClassName(pageId),
      items: getScreenTemplates(screen)
    }
  };
};
