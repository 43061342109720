import BaseButton from "@components-core/BaseButton";
import ExternalLink from "@components-core/ExternalLink";
import RouteLink from "@components-core/RouteLink";
import {
  PAGE_HASH_USER_EDIT_ADDRESSES,
  PAGE_HASH_USER_EDIT_FAVORITES,
  PAGE_HASH_USER_EDIT_PASSWORD,
  PAGE_HASH_USER_EDIT_PROFILE,
  PAGE_HASH_USER_LOGOUT,
  PAGE_HASH_USER_ORDER_HISTORY,
  PAGE_HASH_USER_PAYMENT_HISTORY,
  PAGE_KEY_LOGIN,
  PAGE_KEY_LOGOUT,
  PAGE_KEY_USER_PROFILE,
  PRODUCT_SELECTOR_TYPE_FAVORITE
} from "@constants";
import {
  applyFilterFailure,
  applyFilterSuccess,
  fetchFiltredData
} from "@redux-actions/filters";
import { pushPageBreadcrumb } from "@redux-actions/breadcrumbs";
import {
  //
  userDismissWelcome,
  userProfileUpdate,
  userProfileUpdateFailure,
  userProfileUpdateSuccess
} from "@redux-actions/user";
import { getLoginStatus } from "@redux-utils";
import { UserProfileBS } from "@style-variables";
import { shallowDeepCompare } from "@utils/array";
import { debug } from "@utils/debug";
import { validPassword } from "@utils/password";
import { dangerouslySetInnerHTML } from "@utils/react";
import {
  getComponentClassName,
  joinNonEmptyStrings,
  validConfirmPassword,
  validEmail
} from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import UserAddressList from "./AddressList";
import UserFavoriteList from "./FavoriteList";
import UserFormValidation from "./FormValidation";
import UserMenuBar, { getActiveMenuGroupByHash } from "./MenuBar";
import UserOrderList from "./OrderList";
import UserPaymentList from "./PaymentList";

class UserProfile extends UserFormValidation {
  /**
   * @inheritdoc
   * @memberof UserProfile
   */
  static get mapStateToProps() {
    return (state, ownProps) => {
      const userProfile = state[this.prefix];

      const loginStatus = getLoginStatus(state.userLogin);
      const loggedUser = loginStatus.loggedUser;

      return {
        updateProfileResult: {
          error:
            !userProfile.isFetching && userProfile.error
              ? userProfile.error
              : null,
          success:
            !userProfile.isFetching && userProfile.status
              ? userProfile.status.updateUserProfile
              : null
        },
        loggedUser,
        loginStatus,
        [this.prefix + this.suffix]: loggedUser
          ? {
              firstName: loggedUser.firstName,
              lastName: loggedUser.lastName,
              userName: loggedUser.userName,
              email: loggedUser.email,
              password: null,
              newPassword: null,
              confirmPassword: null,
              roles: loggedUser.roles.join(", ")
            }
          : this.defaultFieldValues,
        favoriteItems: state.productFavoriteResult.items,
        favoriteProducts: state.productFilterResult.items
      };
    };
  }

  /**
   * @inheritdoc
   * @memberof UserProfile
   */
  static get mapDispatchToProps() {
    return {
      userProfileUpdate,
      userProfileUpdateSuccess,
      userProfileUpdateFailure,
      //
      applyFilterFailure,
      applyFilterSuccess,
      fetchFiltredData,
      //
      userDismissWelcome,
      //
      pushPageBreadcrumb
    };
  }

  /**
   * @inheritdoc
   * @memberof UserProfile
   */
  static get mapValueToProps() {
    return value => {
      const i18n = value.i18n.components.UserProfile;

      return {
        ...super.mapValueToProps(value),
        supportsTagDiscount: value.supportsTagDiscount,
        setup: {
          fields: {
            firstName: i18n.LABEL_FIRSTNAME,
            lastName: i18n.LABEL_LASTNAME,
            userName: i18n.LABEL_USERNAME,
            email: i18n.LABEL_EMAIL,
            password: i18n.LABEL_PASSWORD,
            newPassword: i18n.LABEL_NEW_PASSWORD,
            confirmPassword: i18n.LABEL_CONFIRM_PASSWORD,
            roles: i18n.LABEL_ROLES
          },
          btnSave: i18n.BTN_SAVE_PROFILE,
          btnCancel: i18n.BTN_CANCEL,
          btnEdit: i18n.BTN_EDIT_PROFILE,
          btnChangePassword: i18n.BTN_CHANGE_PASSWORD,
          btnLogout: i18n.BTN_LOGOUT,
          btnUpdateAcknowledge: i18n.BTN_UPDATE_ACKNOWLEDGE,
          btnBenchmark: i18n.BTN_BENCHMARK,
          btnBuild: i18n.BTN_BUILD,
          btnMyAccount: i18n.BTN_MY_ACCOUNT,
          btnManageAddresses: i18n.BTN_MANAGE_ADDRESSES,
          btnManageFavorites: i18n.BTN_MANAGE_FAVORITES,
          btnMyOrders: i18n.BTN_MY_ORDERS,
          btnOrderHistory: i18n.BTN_ORDER_HISTORY,
          btnPaymentHistory: i18n.BTN_PAYMENT_HISTORY
        }
      };
    };
  }

  /**
   * @inheritdoc
   * @memberof UserProfile
   */
  static get validationRules() {
    return {
      firstName: 2,
      lastName: 3,
      email: validEmail,
      password: validPassword,
      newPassword: validPassword,
      confirmPassword: (password, values) =>
        validConfirmPassword(values.newPassword, password)
    };
  }

  /**
   * @inheritdoc
   * @memberof UserProfile
   */
  static get prefix() {
    return "userProfile"; // defined by reducer
  }

  /**
   * @inheritdoc
   * @memberof UserProfile
   */
  static get suffix() {
    return "Data";
  }

  /**
   * @description Get the component default state
   * @readonly
   * @static
   * @memberof UserProfile
   */
  static get defaultState() {
    return {
      alert: false,
      alertMessage: null,
      alertVariant: null,
      editing: false,
      changePassword: false,
      changeFavorites: false,
      changeAddresses: false,
      paymentHistory: false
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      ...this.getStateByHash()
    };

    this.handleUserProfileSave = this.handleUserProfileSave.bind(this);
    this.handleUserProfileCancel = this.handleUserProfileCancel.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();

    window.addEventListener("hashchange", this.scrollToTop);

    if (!this.props.loginStatus.isUser) {
      this.navigateDefaultRoute();
    }

    if (PAGE_HASH_USER_EDIT_FAVORITES === this.props.location.hash) {
      this.fetchUserFavorites();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.scrollToTop);
  }

  /**
   * @description Set the page location as the user default route
   * @memberof UserProfile
   */
  navigateDefaultRoute() {
    this.props.history.push(this.props.pathfinder.generate(PAGE_KEY_LOGIN));

    this.scrollToTop();
  }

  /**
   * @description Get the state for the given route hash
   * @param {String} [hash=null] The route hash when given, otherwise the current page hash
   * @returns {Object} Returns the state
   * @memberof UserProfile
   */
  getStateByHash(hash = null) {
    const _hash = hash || this.props.location.hash;

    const defaultState = UserProfile.defaultState;

    if (_hash) {
      const acl = this.getUserACL();

      if (!acl[_hash]) {
        this.navigateDefaultRoute();

        return defaultState;
      }
    }

    let deliveryAddress;
    let invoiceAddress;

    switch (_hash) {
      case PAGE_HASH_USER_EDIT_PROFILE:
        return {
          ...defaultState,
          editing: true,
          [this.getStatic("prefix")]:
            this.props[this.getStatic("prefix") + this.getStatic("suffix")]
        };
      case PAGE_HASH_USER_EDIT_PASSWORD:
        return {
          ...defaultState,
          editing: true,
          changePassword: true
        };
      case PAGE_HASH_USER_LOGOUT:
        this.props.history.push(
          this.props.pathfinder.generate(PAGE_KEY_LOGOUT)
        );
        break;
      case PAGE_HASH_USER_ORDER_HISTORY:
        return {
          ...defaultState
        };
      case PAGE_HASH_USER_EDIT_ADDRESSES:
        deliveryAddress =
          this.props.loggedUser.deliveryAddress ||
          UserAddressList.defaultAddress;

        invoiceAddress =
          this.props.loggedUser.invoiceAddress ||
          UserAddressList.defaultAddress;

        return {
          ...defaultState,
          editing: true,
          changeAddresses: true,
          changeAddressAuthorization: false, // the password/authorization
          changeAddressAuthorize: false, // the password/authorization input visibility
          deliveryAddress,
          invoiceAddress,
          altInvoiceAddress:
            Object.keys(deliveryAddress).filter(key => invoiceAddress[key])
              .length > 0 && shallowDeepCompare(deliveryAddress, invoiceAddress)
        };
      case PAGE_HASH_USER_EDIT_FAVORITES:
        return {
          ...defaultState,
          editing: true,
          changeFavorites: true
        };
      case PAGE_HASH_USER_PAYMENT_HISTORY:
        return {
          ...defaultState,
          paymentHistory: true
        };
      default:
        return defaultState;
    }
  }

  /**
   * @description Get the user ACL by hash
   * @param {Object} [acl=null] The user menu ACL
   * @param {String} [hash=null] The route hash when given, otherwise the current page hash
   * @returns {Boolean} Returns true if the user has permission for the given hash, false otherwise
   * @memberof UserProfile
   */
  getACLByHash(acl = null, hash = null) {
    acl = acl || this.getUserACL();
    const _hash = hash || this.props.location.hash;

    if (_hash) {
      return acl[hash];
    }

    return true;
  }

  /**
   * @description Handle the user profile save
   * @param {Event} e The event
   * @memberof UserProfile
   */
  handleUserProfileSave(e) {
    try {
      const payload = this.validateObject(
        this.state[this.getStatic("prefix")],
        this.getStatic("prefix")
      );

      this.setState({ alert: true });

      let payload1 = {
        siteId: +this.props.siteId,
        userId: +this.props.loggedUser.userId,
        password: payload.password
      };

      if (this.state.changePassword) {
        payload1 = { ...payload1, newPassword: payload.newPassword };
      } else {
        payload1 = {
          ...payload1,
          email: this.props.loggedUser.email,
          firstName: payload.firstName,
          lastName: payload.lastName,
          newEmail: payload.email
        };
      }

      this.props
        .userProfileUpdate(payload1, this.props.siteConfig)
        .then(this.props.userProfileUpdateSuccess)
        .catch(this.props.userProfileUpdateFailure)
        .finally(() => this.scrollToTop());

      // initialize the password state
      this.setState({
        [this.getStatic("prefix")]: {
          ...this.state[this.getStatic("prefix")],
          password: null,
          newPassword: null,
          confirmPassword: null
        }
      });
    } catch (e) {
      debug(e);
    }
  }

  /**
   * @description Handle the user profile cancel action
   * @param {Event} e The event
   * @memberof UserProfile
   */
  handleUserProfileCancel(e) {
    this.setState(UserProfile.defaultState, () => {
      this.props.history.push(this.props.pathfinder.get(PAGE_KEY_USER_PROFILE));
    });
  }

  fetchUserFavorites() {
    const filters = Object.keys(this.props.favoriteItems)
      .filter(productId => this.props.favoriteItems[productId])
      .map(productId => ({ data: `${productId}`, id: "id", value: true }));

    this.props
      .fetchFiltredData(
        null,
        PRODUCT_SELECTOR_TYPE_FAVORITE,
        filters,
        null,
        this.props.siteConfig
      )
      .then(data => {
        this.props.applyFilterSuccess(data);
      })
      .catch(error =>
        this.props.applyFilterFailure(
          error,
          this.props.i18n.UNEXPECTED_ERROR_CAUSE.context.FILTER
        )
      );
  }

  /**
   * @description Render the form buttons
   * @param {Function} onSave
   * @param {Function} onCancel
   * @returns {JSX}
   * @memberof UserProfile
   */
  getRenderedButtons(onSave, onCancel) {
    return (
      <Row className={getComponentClassName(UserProfileBS, "button")}>
        <Col xs="6" className="text-left">
          <BaseButton
            variant="primary"
            title={this.props.setup.btnSave}
            onClick={onSave}
            size="lg"
          />
        </Col>

        <Col xs="6" className="text-right">
          <BaseButton
            variant="secondary"
            title={this.props.setup.btnCancel}
            onClick={onCancel}
            size="lg"
          />
        </Col>
      </Row>
    );
  }

  /**
   * @inheritdoc
   * @memberof UserProfile
   */
  getRenderedFields() {
    return this.renderFields(this.getStatic("prefix"));
  }

  /**
   * @inheritdoc
   * @memberof UserProfile
   */
  renderButtons() {
    return this.getRenderedButtons(
      this.handleUserProfileSave,
      this.handleUserProfileCancel
    );
  }

  /**
   * @inheritdoc
   * @memberof UserProfile
   */
  getFormFields(prefix) {
    const common = [
      [
        "password",
        null,
        { type: "password", required: true, autoComplete: "current-password" }
      ]
    ];

    if (this.state.changePassword) {
      return [
        ...common,
        [
          "newPassword",
          null,
          { type: "password", required: true, autoComplete: "new-password" }
        ],
        [
          "confirmPassword",
          null,
          { type: "password", required: true, autoComplete: "new-password" }
        ]
      ];
    }

    const canViewRoles =
      this.props.loginStatus.isAdmin ||
      this.props.loginStatus.isSuperAdmin ||
      this.props.loginStatus.isDeveloper;

    return [
      [
        [
          "firstName",
          { xs: 12, sm: 6, as: Col },
          { required: true, autoComplete: "given-name" }
        ],
        [
          "lastName",
          { xs: 12, sm: 6, as: Col },
          { required: true, autoComplete: "family-name" }
        ]
      ],
      ["userName", null, { disabled: true, autoComplete: "username" }],
      canViewRoles ? ["roles", null, { disabled: true }] : null,
      ["email", null, { type: "email", required: true, autoComplete: "email" }],
      ...common
    ].filter(Boolean);
  }

  getUserExtendedACL() {
    const provider = this.props.loggedUser
      ? this.props.loggedUser.provider
      : null;

    const builtIn = provider === "builtin";

    // these could be set based on the user role: this.props.loginStatus
    return {
      [PAGE_HASH_USER_EDIT_ADDRESSES]: false, //!builtIn,
      [PAGE_HASH_USER_EDIT_FAVORITES]: false, //!builtIn,
      [PAGE_HASH_USER_ORDER_HISTORY]: !builtIn,
      [PAGE_HASH_USER_PAYMENT_HISTORY]: false //!builtIn
    };
  }

  getUserACL() {
    const defaultACL = {
      [PAGE_HASH_USER_EDIT_PASSWORD]: true,
      [PAGE_HASH_USER_EDIT_PROFILE]: true,
      [PAGE_HASH_USER_LOGOUT]: true
    };

    const extACL = this.getUserExtendedACL();

    return { ...defaultACL, ...extACL };
  }

  /**
   * @inheritdoc
   * @memberof UserProfile
   */
  onControlKeyUp(e) {
    this.handleUserProfileSave(e);
  }

  renderUserMenus() {
    const i18n = this.props.setup;

    const acl = this.getUserACL();

    const defaultActiveKey = getActiveMenuGroupByHash(
      this.props.location.hash,
      {},
      acl
    );

    return (
      <UserMenuBar
        defaultActiveKey={defaultActiveKey}
        hash={this.props.location.hash}
        i18n={{
          myAccount: i18n.btnMyAccount,
          manageAddresses: i18n.btnManageAddresses,
          manageFavorites: i18n.btnManageFavorites,
          editProfile: i18n.btnEdit,
          changePassword: i18n.btnChangePassword,
          logout: i18n.btnLogout,
          //
          myOrders: i18n.btnMyOrders,
          orderHistory: i18n.btnOrderHistory,
          paymentHistory: i18n.btnPaymentHistory
        }}
        acl={acl}
        onChange={(e, nestedPage) =>
          // give the src/components/core/Root time to call first the `pushPageBreadcrumb`
          setTimeout(() => this.props.pushPageBreadcrumb(nestedPage), 1)
        }
      />
    );
  }

  /**
   * @description Get the profile page header
   * @returns {JSX}
   * @memberof UserProfile
   */
  renderHeader() {
    const customerRef =
      this.props.loggedUser.customerNumber || this.props.loggedUser.customerId;

    return (
      <Container className="my-2 text-center">
        <Row>
          <Col className="font-weight-bolder">
            {"Howdy %firstName%,".replace(
              "%firstName%",
              this.props.loggedUser.firstName
            )}
          </Col>
        </Row>
        <Row className="my-2">
          {dangerouslySetInnerHTML(
            "You are logged in as %email%".replace(
              "%email%",
              this.props.loggedUser.email.concat(
                customerRef
                  ? ` (<span class="user-select-all">${customerRef}</span>)`
                  : ""
              )
            ),
            Col
          )}
        </Row>
      </Container>
    );
  }

  /**
   * @description Render the user profile edit form
   * @returns {JSX}
   * @memberof UserProfile
   */
  renderForm(hasACL) {
    const i18n = this.props.i18n.components.UserProfile;

    return hasACL ? (
      <Container className="col-md-6 my-5">
        <Card
          className={getComponentClassName(
            UserProfileBS,
            null,
            joinNonEmptyStrings(this.props.className, "m-3", " ")
          )}
        >
          <Card.Header className="font-weight-bold">
            {i18n.FORM_TITLE.concat(
              this.props.loggedUser.customerNumber
                ? ` (${this.props.loggedUser.customerNumber})`
                : ""
            )}
          </Card.Header>
          <Card.Body>{this.getForm()}</Card.Body>
        </Card>
      </Container>
    ) : null;
  }

  /**
   * @description Render the alert message base on the current update status
   * @returns {JSX}
   * @memberof UserProfile
   */
  renderAlertMessage() {
    const status = this.props.updateProfileResult;
    const i18n = this.props.i18n.components.UserProfile;

    let message = this.state.alertMessage;
    let variant = this.state.alertVariant;

    if (this.state.alert) {
      if (status.error) {
        message = this.getErrorStatus(status.error);
        variant = "danger";
      } else if (status.success) {
        message = i18n.W_NO_CHANGE;
        variant = "warning";
        if (status.success.length) {
          message = i18n.MSG_UPDATE_SUCCESS.replace(
            "%fields%",
            status.success.map(item => item.fieldName).join(",")
          );
          variant = "success";
        }
      }
    }

    return message
      ? this.renderAlert(
          <React.Fragment>
            <Container className="m-2">{message}</Container>
            <BaseButton
              variant="primary"
              title={this.props.setup.btnUpdateAcknowledge}
              onClick={e =>
                this.setState(this.getStateByHash(), this.scrollToTop)
              }
            />
          </React.Fragment>,
          {
            variant
          }
        )
      : null;
  }

  renderUserFirstLoginWelcome() {
    const onDismiss = e => this.props.userDismissWelcome();

    return this.renderAlert(
      <React.Fragment>
        <h1>Welcome!</h1>
        <p>
          Since this is your first time you are logging in make sure to{" "}
          <RouteLink
            href={
              this.props.pathfinder.generate(PAGE_KEY_USER_PROFILE) +
              PAGE_HASH_USER_EDIT_PROFILE
            }
            onClick={onDismiss}
          >
            update your profile settings
          </RouteLink>
          .
        </p>
        <p>
          In order to keep your personal data safe and prevent someone else from
          getting in to your account{" "}
          <ExternalLink href="https://passwordsgenerator.net">
            use a strong password
          </ExternalLink>
          .
        </p>
      </React.Fragment>,
      { variant: "warning" },
      true,
      onDismiss
    );
  }

  renderUserFavoriteList(hasACL) {
    return hasACL ? (
      <UserFavoriteList
        items={this.props.favoriteProducts}
        supportsTagDiscount={this.props.supportsTagDiscount}
      />
    ) : null;
  }

  renderUserAddressList(hasACL) {
    return hasACL ? (
      <UserAddressList
        altInvoiceAddress={this.state.altInvoiceAddress}
        deliveryAddress={this.state.deliveryAddress}
        invoiceAddress={this.state.invoiceAddress}
        authorization={
          this.state.changeAddressAuthorization ||
          this.state.changeAddressAuthorize
        }
        onAltInvoiceAddress={altInvoiceAddress =>
          this.setState({ altInvoiceAddress })
        }
        onSetDeliveryAddress={deliveryAddress =>
          this.setState({ deliveryAddress })
        }
        onSetInvoiceAddress={invoiceAddress =>
          this.setState({ invoiceAddress })
        }
        beforeSave={payload => {
          // this should not happen, just in case
          if (this.state.changeAddressAuthorization) {
            return this.state.changeAddressAuthorization;
          }

          if (this.state.changeAddressAuthorize) {
            if (this.state.deliveryAddress.password) {
              this.setState({
                changeAddressAuthorization: payload.password
              });

              return payload.password;
            }

            // this should not happen, just in case
            throw new Error(`Invalid password`);
          } else {
            this.setState({ changeAddressAuthorize: true });
          }
        }}
        afterSave={(success, error) => {
          this.setState(
            {
              changeAddressAuthorization: false,
              alert: true,
              alertMessage: error
                ? error.message
                : `Address saved successfully`,
              alertVariant: error ? "danger" : success ? "success" : "warning"
            },
            () => this.scrollToTop()
          );
        }}
        onCancel={this.handleUserProfileCancel}
      />
    ) : null;
  }

  renderUserOrderList(hasACL) {
    return hasACL ? (
      <Container className="my-3">
        <UserOrderList />
      </Container>
    ) : null;
  }

  renderUserPaymentList(hasACL) {
    return hasACL ? <UserPaymentList /> : null;
  }

  render() {
    if (!this.props.loginStatus.isUser) {
      return null;
    }

    let form = null;
    let salutation = null;
    let orderList = null;
    let paymentList = null;
    let favoriteList = null;
    let addressList = null;
    let welcome = null;

    const alerts = this.renderAlertMessage();
    const menus = this.renderUserMenus();

    salutation = this.renderHeader();

    const acl = this.getUserACL();

    if (this.props.loggedUser.isFirstTimeLogin) {
      welcome = this.renderUserFirstLoginWelcome();
    } else if (!this.state.editing) {
      if (this.state.paymentHistory) {
        const hasACL = this.getACLByHash(acl, PAGE_HASH_USER_PAYMENT_HISTORY);
        paymentList = this.renderUserPaymentList(hasACL);
      } else {
        const hasACL = this.getACLByHash(acl, PAGE_HASH_USER_ORDER_HISTORY);
        orderList = this.renderUserOrderList(hasACL);
      }
    } else {
      if (this.state.changeFavorites) {
        const hasACL = this.getACLByHash(acl, PAGE_HASH_USER_EDIT_FAVORITES);
        favoriteList = this.renderUserFavoriteList(hasACL);
      } else if (this.state.changeAddresses) {
        const hasACL = this.getACLByHash(acl, PAGE_HASH_USER_EDIT_ADDRESSES);
        addressList = this.renderUserAddressList(hasACL);
      } else {
        if (!this.state.alert) {
          const hasACL = this.getACLByHash(acl, PAGE_HASH_USER_EDIT_PROFILE);
          form = this.renderForm(hasACL);
        }
      }
    }

    return (
      <Container>
        <Row>
          <Col md="3">{menus}</Col>
          <Col md="9">
            {alerts}
            {salutation}
            {welcome}
            {form}
            {orderList}
            {paymentList}
            {favoriteList}
            {addressList}
          </Col>
        </Row>
      </Container>
    );
  }
}

UserProfile.propTypes = {
  ...UserFormValidation.propTypes,
  className: PropTypes.string
};

UserProfile.defaultProps = {
  ...UserFormValidation.defaultProps,
  className: UserProfileBS
};

export default UserProfile.connectHOC;
