import { PAGE_KEY_RESET_PASSWORD } from "@constants";
import { UserResetPasswordBS } from "@style-variables";
import { mergeTemplateItems } from "../common/utils";
import { createLazyTemplateItem } from "../utils";

const TEMPLATE_KEY = "user/ResetPasswordTemplate";

const ResetPasswordTemplate = props => {
  const defaultTemplate = [
    {
      component: "User/ResetPassword",
      props: {
        className: "bg-light " + UserResetPasswordBS
      }
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

ResetPasswordTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_RESET_PASSWORD,
  templateKey: TEMPLATE_KEY
};

export default ResetPasswordTemplate;
