import {
  PAGE_KEY_RECOVERY_PASSWORD,
  PAGE_KEY_RESET_PASSWORD
} from "@constants";
import { UserResetPasswordPageBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";

export default ({ siteId, i18n, graphql, screen, imgParams, siteContext }) => {
  const pageId = UserResetPasswordPageBS;

  const defaultResetHelmet = {
    title: "user-reset-password-page",
    meta: {
      name: {
        description: "user reset password page",
        // keywords: "x,y,z",
        robots: "noindex"
      }
    }
  };

  const defaultRecoveryHelmet = {
    title: "user-recovery-password-page",
    meta: {
      name: {
        description: "user recovery password page",
        // keywords: "x,y,z",
        robots: "noindex"
      }
    }
  };

  const resetHelmet = mergeHelmet(
    mergeHelmet(defaultResetHelmet, siteContext.helmet),
    screen.helmet
  );

  const recoveryHelmet = mergeHelmet(
    mergeHelmet(defaultRecoveryHelmet, siteContext.helmet),
    screen.helmet
  );

  return {
    [PAGE_KEY_RESET_PASSWORD]: {
      id: pageId,
      helmet: resetHelmet,
      className: getComponentClassName(pageId),
      items: getScreenTemplates(screen)
    },
    [PAGE_KEY_RECOVERY_PASSWORD]: {
      id: pageId,
      helmet: recoveryHelmet,
      className: getComponentClassName(pageId),
      items: getScreenTemplates(screen)
    }
  };
};
