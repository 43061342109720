import AlertDismissible from "@components-core/AlertDismissible";
import PureComponent from "@components-core/PureComponent";
import { PAGE_KEY_HOME, PAGE_KEY_HTTP_ERROR_404 } from "@constants";
import { userLogout } from "@redux-actions/user";
import { withReduxContext } from "@redux-context";
import { withSiteContext } from "@site-context";
import { UserLogoutBS } from "@style-variables";
import { isAdminConfig } from "@utils/functions";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";

class UserLogout extends PureComponent {
  componentDidMount() {
    const trusted =
      (this.props.loginStatus.loggedUser || {}).trusted || isAdminConfig();

    if (!(trusted || this.props.security.enabled)) {
      this.props.history.push(
        this.props.pathfinder.generate(PAGE_KEY_HTTP_ERROR_404)
      );
    } else if (this.props.loginStatus.isLogged) {
      this.props.userLogout();

      this.props.history.push(this.props.pathfinder.generate(PAGE_KEY_HOME));
    }
  }

  render() {
    if (this.props.isAnonymous) {
      return null;
    }

    const i18n = this.props.i18n.components.UserLogout;

    if (!this.props.loginStatus.isLogged) {
      const onClick = e =>
        this.props.history.push(this.props.pathfinder.generate(PAGE_KEY_HOME));
      return (
        <AlertDismissible
          variant="warning"
          body={i18n.W_NOT_LOGGED}
          onDismiss={onClick}
          buttons={[{ onClick, children: i18n.BTN_HOME }]}
          className="text-center m-3"
        />
      );
    }

    return (
      <Container className="text-center m-3">
        <Row>
          <Col>
            <p className="dotdotdot">{i18n.LOGOUT_LABEL}</p>
          </Col>
        </Row>
      </Container>
    );
  }
}

UserLogout.propTypes = {
  className: PropTypes.string
};

UserLogout.defaultProps = {
  className: UserLogoutBS
};

const mapStateToProps = (state, ownProps) => null;

const mapDispatchToProps = {
  userLogout
};

const WithSiteUserLogout = props => withSiteContext(UserLogout)(props);

const WithReduxUserLogout = withReduxContext(
  mapStateToProps,
  mapDispatchToProps
)(WithSiteUserLogout);

const WithRouterUserLogout = props => withRouter(WithReduxUserLogout)(props);

export default WithRouterUserLogout;
