import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import PropTypes from "prop-types";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ProductTitle from "../Product/Title";
import { renderImageThumbnail } from "../ProductCategory/utils";

const UserFavoriteList = props => {
  if (!props.items) {
    return null;
  }

  const favoriteProducts = props.items.length ? (
    props.items.map((item, i) => (
      <Row key={i}>
        <Col md="3">
          {renderImageThumbnail({
            productItem: item,
            tagDiscount: true,
            supportsTagDiscount: props.supportsTagDiscount,
            placeholder: false,
            imgSize: { aspect: 1, maxHeight: 80, maxWidth: 120, minHeight: 80 }
          })({})}
        </Col>
        <Col md="8">
          <ProductTitle
            as={props.titleAs || "h4"}
            title={item.title}
            subtitle={item.subtitle}
            onClick={item.buttons.product.onClick}
            placeholder={props.placeholder}
            href={item.buttons.product.href}
          />
        </Col>
        <Col md="1">
          <Button variant="danger">
            <FontAwesomeIcon icon="trash-alt" />
          </Button>
        </Col>
      </Row>
    ))
  ) : (
    <Row className="text-danger text-center p-2">
      <Col>You have no favorite items yet.</Col>
    </Row>
  );

  return <Container>{favoriteProducts}</Container>;
};

UserFavoriteList.propTypes = {
  supportsTagDiscount: PropTypes.bool,
  ...ItemsAwareProps()
};

export default UserFavoriteList;
