import BaseButton from "@components-core/BaseButton";
import { connectHOCs } from "@components-utils";
import Address from "@components/Address/Address";
import {
  userAddressUpdate,
  userAddressUpdateFailure,
  userAddressUpdateSuccess
} from "@redux-actions/user";
import { UserAddressListBS } from "@style-variables";
import { setDefaultValue } from "@utils/array";
import { validPassword } from "@utils/password";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";

class UserAddressList extends Address {
  static validationRules = {
    ...Address.validationRules,
    password: password => validPassword(password, false)
  };

  constructor(props) {
    super(props);

    this.handleUserAddressSave = this.handleUserAddressSave.bind(this);

    this.state = { ...this.state, address: null };
  }

  getFormFields(prefix) {
    const fields = super.getFormFields(prefix);

    if (true === this.props.authorization && "delivery" === prefix) {
      fields.push([
        "password",
        null,
        { type: "password", required: true, autoComplete: "current-password" }
      ]);
    }

    return fields;
  }

  /**
   * @description Validates the delivery address
   * @param {Object} address
   * @returns {Object}
   * @memberof UserAddressList
   */
  validateDeliveryAddress(address) {
    return this.validateObject(address, "delivery");
  }

  /**
   * @description Validates the invoice address
   * @param {Object} address
   * @param {Boolean} shouldValidate
   * @returns {Object}
   * @memberof UserAddressList
   */
  validateInvoiceAddress(address, shouldValidate) {
    if (!shouldValidate) {
      return address;
    }

    return this.validateObject(address, "invoice");
  }

  /**
   * @description Handle the user address save
   * @memberof UserAddressList
   */
  handleUserAddressSave() {
    try {
      const deliveryAddress = this.validateDeliveryAddress(
        setDefaultValue(this.props.deliveryAddress)
      );

      const invoiceAddress = this.validateInvoiceAddress(
        setDefaultValue(this.props.invoiceAddress),
        this.props.altInvoiceAddress
      );

      const loggedUser = this.props.loginStatus.loggedUser;

      const payload = {
        siteId: this.props.siteConfig.siteId,
        userId: this.props.siteConfig.userId,
        password: deliveryAddress.password,
        provider: loggedUser.provider,
        deliveryAddress: {
          ...deliveryAddress,
          status: undefined,
          password: undefined
        },
        altInvoiceAddress: this.props.altInvoiceAddress
      };

      if (this.props.altInvoiceAddress) {
        payload.invoiceAddress = { ...invoiceAddress, status: undefined };
      }

      let saveAuthorization = true;

      if ("function" === typeof this.props.beforeSave) {
        saveAuthorization = this.props.beforeSave(payload);
      }

      if (saveAuthorization) {
        if ("string" === typeof saveAuthorization) {
          payload.password = saveAuthorization;
        }

        this.props
          .userAddressUpdate(payload, this.props.siteConfig)
          .then(result => {
            this.props.userAddressUpdateSuccess(result.updateUserAddress);
            if ("function" === typeof this.props.afterSave) {
              this.props.afterSave(result.updateUserAddress);
            }
          })
          .catch(error => {
            this.props.userAddressUpdateFailure(error);
            if ("function" === typeof this.props.afterSave) {
              this.props.afterSave(false, error);
            }
          });
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * @inheritdoc
   * @memberof UserAddressList
   */
  renderButtons() {
    return (
      <Row className={getComponentClassName(UserAddressListBS, "button")}>
        <Col xs="6" className="text-left">
          <BaseButton
            variant="primary"
            title={this.props.btnSave}
            onClick={this.handleUserAddressSave}
            size="lg"
          />
        </Col>

        <Col xs="6" className="text-right">
          <BaseButton
            variant="secondary"
            title={this.props.btnCancel}
            onClick={this.props.onCancel}
            size="lg"
          />
        </Col>
      </Row>
    );
  }
}

UserAddressList.propTypes = {
  ...Address.propTypes,
  authorization: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  beforeSave: PropTypes.func,
  afterSave: PropTypes.func,
  onCancel: PropTypes.func
};

UserAddressList.mapStateToProps = (state, ownProps) => ({});

UserAddressList.mapDispatchToProps = {
  userAddressUpdate,
  userAddressUpdateSuccess,
  userAddressUpdateFailure
  //
};

UserAddressList.mapValueToProps = value => {
  const i18n = value.i18n.components.UserProfile;

  const parentValue = Address.mapValueToProps(value);
  return {
    ...parentValue,

    setup: {
      ...parentValue.setup,

      fields: {
        ...parentValue.setup.fields,
        password: i18n.LABEL_PASSWORD
      }
    },
    btnSave: i18n.BTN_SAVE_PROFILE,
    btnCancel: i18n.BTN_CANCEL
  };
};

const WithGraphQLUserAddressList = connectHOCs(UserAddressList, {
  withSite: true,
  withGraphQL: true,
  withConnect: true
});

WithGraphQLUserAddressList.defaultAddress = Address.defaultAddress;

export default WithGraphQLUserAddressList;
