import AlertDismissible from "@components-core/AlertDismissible";
import { connectHOCs } from "@components-utils";
import { PAGE_KEY_HTTP_ERROR_404 } from "@constants";
import { UserFormValidationBS } from "@style-variables";
import { isAdminConfig } from "@utils/functions";
import { validPassword } from "@utils/password";
import PropTypes from "prop-types";
import React from "react";
import { Alert, Button, Col, Container } from "react-bootstrap";
import FormValidation from "../Form/Validation";

/**
 * @description An abstract React component which provides an User form validation support.
 * @export
 * @class UserFormValidation
 * @extends {FormValidation}
 */
export default class UserFormValidation extends FormValidation {
  /**
   * @description Maps the Redux state as component props
   * @see https://react-redux.js.org/api/connect#mapstatetoprops-state-ownprops-object
   * @readonly
   * @static
   * @memberof UserFormValidation
   */
  static get mapStateToProps() {
    return null;
  }

  /**
   * @description Maps the Redux actions as component props
   * @see https://react-redux.js.org/api/connect#mapdispatchtoprops-object-dispatch-ownprops-object
   * @readonly
   * @static
   * @memberof UserFormValidation
   */
  static get mapDispatchToProps() {
    return null;
  }

  /**
   * @description Maps the site context value as component props
   * @see ../../sites/SiteContext.js
   * @readonly
   * @static
   * @memberof UserFormValidation
   */
  static get mapValueToProps() {
    return value => {
      return {
        //[this.prefix + this.suffix]: this.defaultFieldValues
      };
    };
  }

  /**
   * @description Get the form validatio rules
   * @readonly
   * @static
   * @memberof UserFormValidation
   */
  static get validationRules() {
    return {};
  }

  /**
   * @description Get the form prefix
   * @readonly
   * @static
   * @memberof UserFormValidation
   */
  static get prefix() {
    return null;
  }

  /**
   * @description Get the form suffix
   * @readonly
   * @static
   * @memberof UserFormValidation
   */
  static get suffix() {
    return null;
  }

  /**
   * @description Get the form default fields values
   * @readonly
   * @static
   * @memberof UserFormValidation
   */
  static get defaultFieldValues() {
    return Object.keys(this.validationRules)
      .map(key => ({ [key]: "" }))
      .reduce((carry, item) => Object.assign(carry, item), {});
  }

  /**
   * @description Connects the component to the Redux/SiteContext/GraphQLClient HOC components
   * @readonly
   * @static
   * @memberof UserFormValidation
   */
  static get connectHOC() {
    return connectHOCs(this, {
      withSite: true,
      withRedux: true,
      withGraphQL: true,
      withRouter: true
    });
  }

  /**
   * @description Get the value of a static defined property/function
   * @param {String} key The static property/function name
   * @param {boolean} [isFunction=false] When true the `key` is assumed to be a function, otherwise property
   * @returns {*}
   * @memberof UserFormValidation
   */
  getStatic(key, isFunction = false) {
    const resolver = this.constructor[key];

    if (isFunction) {
      return resolver();
    }

    return resolver;
  }

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      [this.getStatic("prefix")]: this.getStatic("defaultFieldValues"),
      alert: false,
      changePassword: false
    };

    this.trusted =
      (this.props.loginStatus.loggedUser || {}).trusted || isAdminConfig();
  }

  componentDidMount() {
    if (!(this.trusted || this.props.security.enabled)) {
      this.props.history.push(
        this.props.pathfinder.generate(PAGE_KEY_HTTP_ERROR_404)
      );
    }

    //super.componentDidMount();
  }

  /**
   * @inheritdoc
   * @memberof UserFormValidation
   */
  getDefaultFeedbackState() {
    return {
      [this.getStatic("prefix")]: this.validationRulesToFeedback()
    };
  }

  /**
   * @inheritdoc
   * @memberof UserFormValidation
   */
  getFormChangeAction(prefix, name) {
    return value => {
      this.setState({
        [prefix]: {
          ...this.state[prefix],
          ...value
        }
      });
    };
  }

  /**
   * @inheritdoc
   * @memberof UserFormValidation
   */
  getFormChangeActionProps(e, prefix, name) {
    return { [name]: e.target.value };
  }

  /**
   * @inheritdoc
   * @memberof UserFormValidation
   */
  getStateKey(prefix) {
    return prefix + this.getStatic("suffix");
  }

  /**
   * @inheritdoc
   * @memberof UserFormValidation
   */
  getValidationRules(fieldName = null) {
    const rules = this.getStatic("validationRules");

    if (this.props.security && !this.props.security.enforceStrongPassword) {
      ["password", "newPassword"].forEach(name => {
        if (rules[name]) {
          rules[name] = password => validPassword(password, false);
        }
      });
    }

    if (fieldName) {
      return rules[fieldName];
    }

    return rules;
  }

  /**
   * @description Render a registration success/error alert
   * @param {String|JSX} message The alert children message
   * @param {Object} button The alert child button
   * @param {Boolean} dismissible When true a dismiss-button will be rendered
   * @param {Function} onClose A callback triggered on closing/dismissing the alert
   * @returns {JSX}
   * @memberof UserFormValidation
   */
  renderAlert(message, button, dismissible, onClose) {
    let Component = Alert;

    let props = {
      onClose: e => this.setState({ alert: false }, onClose),
      dismissible: false,
      children: (
        <React.Fragment>
          <Container className="p-2 text-center">{message}</Container>
          {button.onClick ? (
            <Col className="text-center">
              <Button size="lg" {...button} />
            </Col>
          ) : null}
        </React.Fragment>
      )
    };

    if (dismissible) {
      Component = AlertDismissible;
      props = {
        className: "text-center m-3",
        onDismiss: props.onClose,
        onClose: null,
        body: message,
        children: null
      };

      if (button.onClick) {
        props.buttons = [button];
      }
    }

    return <Component variant={button.variant} className="m-3" {...props} />;
  }

  /**
   * @description Render a login error alert
   * @param {String|JSX} message The alert children message
   * @returns {JSX}
   * @memberof UserFormValidation
   */
  renderErrorAlert(message) {
    return this.renderAlert(message, { variant: "danger" }, true);
  }

  /**
   * @description Get the status error message
   * @param {String|Object} error The Redux fetch error
   * @returns {String}
   * @memberof UserProfile
   */
  getErrorStatus(error) {
    const i18n = this.props.i18n.components.UserLogin;
    const err911 = "E_DATASTORE_911";

    if ("string" === typeof error) {
      switch (error) {
        case "E_INVALID_CREDENTIALS_1":
        case "E_INVALID_CREDENTIALS_2":
        case "E_INVALID_CREDENTIALS_3":
          return i18n.E_INVALID_CREDENTIALS;
        default:
          if (error.indexOf(err911) === 0) {
            return this.props.i18n.E_DATASTORE_911.replace(
              "%site_id%",
              error.slice(err911.length)
            );
          }

          return error;
      }
    } else {
      return error.error;
    }
  }
}

UserFormValidation.propTypes = {
  ...FormValidation.propTypes,
  className: PropTypes.string
};

UserFormValidation.defaultProps = {
  ...FormValidation.defaultProps,
  className: UserFormValidationBS
};
