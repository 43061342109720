import { PAGE_KEY_LOGOUT } from "@constants";
import { mergeTemplateItems } from "../common/utils";
import { createLazyTemplateItem } from "../utils";

const TEMPLATE_KEY = "user/LogoutTemplate";

const UserLogoutTemplate = props => {
  const defaultTemplate = [
    {
      component: "User/Logout"
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

UserLogoutTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_LOGOUT,
  templateKey: TEMPLATE_KEY
};

export default UserLogoutTemplate;
