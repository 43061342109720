import BaseButton from "@components-core/BaseButton";
import RouteLink from "@components-core/RouteLink";
import {
  PAGE_KEY_ADMIN_TOOLBOX,
  PAGE_KEY_LOGOUT,
  PAGE_KEY_USER_PROFILE
} from "@constants";
import {
  userLogin,
  userLoginFailure,
  userLoginSuccess
} from "@redux-actions/user";
import { UserLoginBS } from "@style-variables";
import { debug } from "@utils/debug";
import { validPassword } from "@utils/password";
import {
  getComponentClassName,
  joinNonEmptyStrings,
  validEmail
} from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import UserFormValidation from "./FormValidation";
import { loginProviders, LOGIN_PROVIDER_EMAIL } from "./LoginProvider";

// TODO implement secure 2nd-factor https://developer.mozilla.org/en-US/docs/Web/API/Web_Authentication_API
// see https://www.npmjs.com/package/speakeasy, supports Google Authenticator and other two-factor devices
class UserLogin extends UserFormValidation {
  /**
   * @inheritdoc
   * @memberof UserLogin
   */
  static get mapStateToProps() {
    return (state, ownProps) => {
      // ex: these can be set with default values from cookies/localStorage
      let defaultState = {
        userName: null,
        password: null
      };

      if (ownProps.match.params.hash) {
        try {
          defaultState = JSON.parse(atob(ownProps.match.params.hash));
          defaultState = {
            userName: defaultState.userReg,
            password: defaultState.password
          };
        } catch (e) {}
      }

      return {
        [this.prefix + this.suffix]: defaultState
      };
    };
  }

  /**
   * @inheritdoc
   * @memberof UserLogin
   */
  static get mapDispatchToProps() {
    return {
      userLogin,
      userLoginFailure,
      userLoginSuccess
    };
  }

  /**
   * @inheritdoc
   * @memberof UserLogin
   */
  static get mapValueToProps() {
    return value => ({
      ...super.mapValueToProps(value),
      setup: {
        fields: {
          userName: value.i18n.components.UserLogin.LABEL_USERNAME,
          password: value.i18n.components.UserLogin.LABEL_PASSWORD
        },
        button: value.i18n.components.UserLogin.BTN_LOGIN
      }
    });
  }

  /**
   * @inheritdoc
   * @memberof UserLogin
   */
  static get validationRules() {
    return {
      userName: userName =>
        userName && (userName.length >= 5 || validEmail(userName)),
      password: validPassword
    };
  }

  /**
   * @inheritdoc
   * @memberof UserLogin
   */
  static get prefix() {
    return "userLogin"; // defined by reducer
  }

  /**
   * @inheritdoc
   * @memberof UserLogin
   */
  static get suffix() {
    return "Data";
  }

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      [UserLogin.prefix]: {
        ...this.state[UserLogin.prefix],
        ...props[UserLogin.prefix + UserLogin.suffix]
      },
      loginProviderType: null,
      redirectAfterLogin: null
    };

    this.handleUserLogin = this.handleUserLogin.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.props.loginStatus.isLogged) {
      console.log(this.props.history);

      const redirectAfterLogin =
        (this.props.history.location.state || {}).redirectAfterLogin || "";

      console.log({ logged: true, redirectAfterLogin });

      this.props.history.push(
        -1 === redirectAfterLogin.indexOf("/")
          ? this.props.pathfinder.generate(
              redirectAfterLogin || PAGE_KEY_USER_PROFILE
            )
          : redirectAfterLogin
      );
    }
  }

  /**
   * @description Handle the register-user button click event
   * @param {Event} e
   * @memberof UserLogin
   */
  handleUserLogin(e) {
    try {
      const payload = this.validateObject(
        this.state[this.getStatic("prefix")],
        this.getStatic("prefix")
      );

      // validate the reCAPTCHA
      if (!(this.props.reCaptcha.disabled || this.hasReCaptchaToken())) {
        this.setState({
          ...this.state,
          reCaptcha: {
            ...this.state.reCaptcha,
            state: UserLogin.RECAPTCHA_RENDERING,
            onValidated: () => this.handleUserLogin(e)
          }
        });

        return;
      }

      this.setState({ alert: true });

      this.props
        .userLogin(
          {
            siteId: this.props.siteId,
            userName: payload.userName,
            email: payload.email,
            password: payload.password
          },
          this.props.siteConfig
        )
        .then(result => {
          this.props.userLoginSuccess(result);

          const loginStatus = this.props.loginStatus;

          let redirectRouteName =
            (this.props.history.location.state || {}).redirectAfterLogin || "";

          // redirect to user-specific page after login
          if (
            !redirectRouteName &&
            (loginStatus.isAdmin ||
              loginStatus.isSuperAdmin ||
              loginStatus.isDeveloper)
          ) {
            redirectRouteName = this.props.pathfinder.get(
              PAGE_KEY_ADMIN_TOOLBOX,
              null,
              this.props.pathfinder.BOOL_ON_NOTFOUND
            );
          }

          redirectRouteName =
            redirectRouteName ||
            this.props.pathfinder.get(PAGE_KEY_USER_PROFILE);

          this.props.history.push(redirectRouteName);
        })
        .catch(error => {
          this.setState(
            {
              ...this.state,
              reCaptcha: {
                ...this.state.reCaptcha,
                state: null,
                onValidated: null
              }
            },
            () => this.props.userLoginFailure(error)
          );
        });
    } catch (e) {
      debug(e);
    }
  }

  /**
   * @inheritdoc
   * @memberof UserLogin
   */
  getRenderedFields() {
    const i18n = this.props.i18n.components.UserLogin;

    return (
      <React.Fragment>
        {this.renderFields(this.getStatic("prefix"))}
        <Row as={Form.Group}>
          <Col
            className={getComponentClassName(
              UserLoginBS,
              "button",
              "text-md-left text-center"
            )}
            xs="12"
            sm="5"
            md="5"
          >
            <BaseButton
              variant="primary"
              title={this.props.setup.button}
              onClick={this.handleUserLogin}
              size="lg"
            />
          </Col>
          <Col
            className={getComponentClassName(
              UserLoginBS,
              "links",
              "text-md-right text-center small mt-3 mt-sm-1"
            )}
            xs="12"
            sm="7"
            md="7"
          >
            <RouteLink to={this.props.pathfinder.get("recovery-password")}>
              {i18n.LINK_FORGOT_PASSWORD}
            </RouteLink>
            <br />
            <RouteLink to={this.props.pathfinder.get("user-registration")}>
              {i18n.LINK_REGISTER_USER}
            </RouteLink>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  /**
   * @inheritdoc
   * @memberof UserLogin
   */
  getFormFields(prefix) {
    return [
      ["userName", null, { required: true, autoComplete: "username" }],
      [
        "password",
        null,
        {
          type: "password",
          required: true,
          autoComplete: "current-password"
        }
      ]
    ];
  }

  /**
   * @inheritdoc
   * @memberof UserLogin
   */
  onControlKeyUp(e) {
    this.handleUserLogin(e);
  }

  /**
   * @description Render the login providers list
   * @returns {JSX}
   * @memberof UserLogin
   */
  renderLoginProviders() {
    const items = loginProviders
      .sort((a, b) => +a.priority - +b.priority)
      .map(provider => {
        const { button, init, login } = provider.component({
          onClick: e => {
            console.log(`login provider ${provider.type} click event`);
            this.setState({ loginProviderType: provider.type });
            if (init) {
              init().then(oauthClient =>
                login(oauthClient)
                  .then(res => {
                    console.log(res);
                  })
                  .catch(error => {
                    console.error(error);
                  })
              );
            }
          },
          //disabled: this.state.loginProviderType === provider.type,
          alwayseLogin: LOGIN_PROVIDER_EMAIL !== provider.type
        });

        const result = [
          <Row key={provider.type}>
            <Col className="text-center my-1">{button}</Col>
          </Row>
        ];

        if (
          LOGIN_PROVIDER_EMAIL === provider.type &&
          LOGIN_PROVIDER_EMAIL === this.state.loginProviderType
        ) {
          result.unshift(
            <Row key={provider.type + "-login-form"}>
              <Col className="my-1">{this.getForm()}</Col>
            </Row>
          );
        }

        return result;
      });

    return <Container>{items}</Container>;
  }

  render() {
    const i18n = this.props.i18n.components.UserLogin;
    const status = this.props.loginStatus;

    let alert = null;

    if (status.hasError && this.state.alert) {
      const message = this.getErrorStatus(status.fetchError);

      alert = this.renderErrorAlert(message);
    } else if (status.isLogged) {
      alert = this.renderAlert(
        i18n.W_USER_LOGGED.replace("%email%", status.userEmail),
        {
          variant: "success",
          onClick: e =>
            this.props.history.push(
              this.props.pathfinder.generate(PAGE_KEY_LOGOUT)
            ),
          children: i18n.BTN_LOGOUT
        },
        true
      );
    }

    const loginProvidersChoices =
      1 === loginProviders.length
        ? this.getForm()
        : this.renderLoginProviders();

    const form = status.isLogged ? null : (
      <Container className="col-md-8 col-lg-6 my-5">
        <Card
          className={getComponentClassName(
            UserLoginBS,
            null,
            joinNonEmptyStrings(this.props.className, "m-3", " ")
          )}
        >
          <Card.Header className="font-weight-bold">
            {i18n.FORM_TITLE}
          </Card.Header>
          <Card.Body>{loginProvidersChoices}</Card.Body>
        </Card>
      </Container>
    );

    return (
      <React.Fragment>
        {alert}
        {form}
      </React.Fragment>
    );
  }
}

UserLogin.propTypes = {
  ...UserFormValidation.propTypes,
  className: PropTypes.string
};

UserLogin.defaultProps = {
  ...UserFormValidation.defaultProps,
  className: UserLoginBS
};

export default UserLogin.connectHOC;
