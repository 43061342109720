import {
  getRouteByProductSelectorType,
  PRODUCT_SELECTOR_TYPE_SERIES
} from "@constants";
import { mergeTemplateItems } from "../common/utils";
import { createLazyTemplateItem } from "../utils";

const PAGE_KEY = getRouteByProductSelectorType(PRODUCT_SELECTOR_TYPE_SERIES);
const TEMPLATE_KEY = "common/ProductSeriesTemplate";

const ProductSeriesTemplate = props => {
  const defaultTemplate = [
    {
      component: "ProductCategory/Header",
      props: { selectorType: PRODUCT_SELECTOR_TYPE_SERIES }
    },
    {
      component: "ProductCategory/SiteComparator"
    },
    {
      component: "ProductCategory/SiteSelector",
      props: {
        selectorType: PRODUCT_SELECTOR_TYPE_SERIES
      }
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

ProductSeriesTemplate.mapValueToProps = value => ({
  imageFootnoteCardDeck: value.imageFootnoteCardDeck,
  searchBanner: value.searchBanner
});

ProductSeriesTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY,
  templateKey: TEMPLATE_KEY
};

export default ProductSeriesTemplate;
