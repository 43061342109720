import {
  PAGE_HASH_USER_EDIT_ADDRESSES,
  PAGE_HASH_USER_EDIT_FAVORITES,
  PAGE_HASH_USER_EDIT_PASSWORD,
  PAGE_HASH_USER_EDIT_PROFILE,
  PAGE_HASH_USER_LOGOUT,
  PAGE_HASH_USER_ORDER_HISTORY,
  PAGE_HASH_USER_PAYMENT_HISTORY
} from "@constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserMenuBarBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";

const getUserMenus = (i18n, acl, onChange) => {
  const result = [
    {
      title: i18n.myAccount,
      disabled: false,
      icon: null,
      items: {
        [PAGE_HASH_USER_EDIT_PROFILE]: {
          title: i18n.editProfile,
          disabled: false,
          icon: "user"
        },
        [PAGE_HASH_USER_EDIT_ADDRESSES]: {
          title: i18n.manageAddresses,
          disabled: false,
          icon: "map-marker-alt"
        },
        [PAGE_HASH_USER_EDIT_FAVORITES]: {
          title: i18n.manageFavorites,
          disabled: false,
          icon: "heart"
        },
        [PAGE_HASH_USER_EDIT_PASSWORD]: {
          title: i18n.changePassword,
          disabled: false,
          icon: "key"
        },
        [PAGE_HASH_USER_LOGOUT]: {
          title: i18n.logout,
          disabled: false,
          icon: null
        }
      }
    },
    {
      title: i18n.myOrders,
      disabled: false,
      icon: null,
      items: {
        [PAGE_HASH_USER_ORDER_HISTORY]: {
          title: i18n.orderHistory,
          disabled: false,
          icon: "file-alt"
        },
        [PAGE_HASH_USER_PAYMENT_HISTORY]: {
          title: i18n.paymentHistory,
          disabled: false,
          icon: "credit-card"
        }
      }
    }
  ];

  return result.map(item => ({
    ...item,
    items: Object.keys(item.items)
      .filter(key => acl[key])
      .reduce(
        (carry, key) =>
          Object.assign(carry, {
            [key]: { ...item.items[key], onChange }
          }),
        {}
      )
  }));
};

const getActiveMenuGroupByHash = (hash, i18n = {}, acl) => {
  const menus = getUserMenus(i18n, acl);

  const activeMenuGroupIndex = menus.findIndex(({ items }) =>
    Object.keys(items).some(key => key === hash)
  );

  if (-1 !== activeMenuGroupIndex && !menus[activeMenuGroupIndex].disabled) {
    return String(activeMenuGroupIndex);
  }

  return null;
};

const renderMenuItems = (pageHash, items) => {
  return Object.keys(items).map((hash, key) => {
    const className = [
      hash === pageHash ? `active ${hash.slice(1)}` : null,
      items[hash].disabled ? "disabled" : null
    ]
      .filter(Boolean)
      .join(" ");

    const children = {
      onChange: items[hash].onChange,
      cols: [
        items[hash].icon ? (
          <FontAwesomeIcon icon={items[hash].icon} />
        ) : (
          <React.Fragment></React.Fragment>
        ),
        items[hash].disabled ? (
          items[hash].title
        ) : (
          <a href={hash}>{items[hash].title}</a>
        )
      ].filter(Boolean)
    };

    return (
      <Row
        key={key}
        className={className}
        onClick={e => {
          const cb = children.onChange;
          if (!items[hash].disabled && "function" === typeof cb) {
            const nestedPage = {
              title: items[hash].title,
              href: window.location.pathname + hash,
              parent: window.location.pathname,
              isNested: true
            };

            cb(e, nestedPage);
          }
        }}
      >
        {children.cols.map((child, i) => (
          <Col key={i} xs={i ? 10 : 2} sm={i ? 11 : 1} md={i ? 10 : 2}>
            {child}
          </Col>
        ))}
      </Row>
    );
  });
};

const renderMenuGroup = (hash, i18n, menu, key, acl) => {
  const { title, items, disabled } = menu;

  if (!Object.keys(items).length) {
    return null;
  }

  const eventKey = disabled ? null : String(key);

  const children = disabled ? null : (
    <Accordion.Collapse eventKey={eventKey}>
      <Card.Body>
        <ul style={{ listStyleType: "none" }} className="pl-2 m-1">
          {renderMenuItems(hash, items)}
        </ul>
      </Card.Body>
    </Accordion.Collapse>
  );

  const className = [
    key === getActiveMenuGroupByHash(hash, i18n, acl) ? "active" : null,
    disabled ? "disabled" : null
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <Card key={key} className={className}>
      <Accordion.Toggle as={Card.Header} variant="link" eventKey={eventKey}>
        {title}
      </Accordion.Toggle>
      {children}
    </Card>
  );
};

const UserMenuBar = props => {
  const onChange = props.onChange;
  const menus = getUserMenus(props.i18n, props.acl, onChange);

  useEffect(() => {
    const findTitle = menus =>
      menus.reduce((carry, parent) => {
        const title = Object.keys(parent.items).reduce(
          (carry, hash) =>
            carry ||
            (window.location.hash === hash ? parent.items[hash].title : null),
          null
        );

        return carry || title;
      }, null);

    const nestedPage = {
      title: findTitle(menus),
      href: window.location.pathname + window.location.hash,
      parent: window.location.pathname,
      isNested: true
    };

    // when the page is (re)loaded trigger the default menu change
    onChange(null, nestedPage);
  }, [onChange, menus]);

  const menuGroupsCount = menus.filter(
    menu => Object.keys(menu.items).length
  ).length;

  const children = menus.map((menu, key) => {
    if (!Object.keys(menu.items).length) {
      return null;
    }

    return renderMenuGroup(
      props.hash,
      props.i18n,
      menu,
      String(key),
      props.acl
    );
  });

  return (
    <Accordion
      defaultActiveKey={menuGroupsCount > 1 ? props.defaultActiveKey : "0"}
      className={getComponentClassName(UserMenuBarBS)}
    >
      {children}
    </Accordion>
  );
};

UserMenuBar.propTypes = {
  defaultActiveKey: PropTypes.string,
  hash: PropTypes.string.isRequired,
  i18n: PropTypes.object.isRequired,
  acl: PropTypes.objectOf(PropTypes.bool).isRequired,
  onChange: PropTypes.func
};

export { getActiveMenuGroupByHash };

export default UserMenuBar;
