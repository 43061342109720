import React from "react";
import { withRouter } from "react-router";
import { withSiteContext } from "@site-context";
import { IconAlignLeft } from "@style-variables";
import { debug } from "@utils/debug";
import BaseButton from "@components-core/BaseButton";
import GDPRAwareWidget from "../../GDPR/GDPRAwareWidget";
import { applyDoNotTrack } from "../../GDPR/utils";

let oauthClient = null;
let assetsReady = false;

class LoginWithFacebook extends GDPRAwareWidget {
  onGlobalVariableError() {
    debug(
      `The global variable 'FB' is not found. This idicates a bug within ${this.constructor.name} component.`,
      "warn"
    );
  }

  /**
   * @inheritdoc
   * @memberof LoginWithFacebook
   */
  onCookieUsageChanged() {
    // https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out
    // window["ga-disable-" + this.props.identity] = this.state.doNotTrack;

    if ("undefined" !== typeof window.FB) {
      this.setTrackingOption();
    } else {
      this.onGlobalVariableError();
    }
  }

  setTrackingOption() {
    if (this.props.externalCookieController) {
      return;
    }
  }

  componentDidUpdate() {
    if (this.props.assetsReady) {
      this.onCookieUsageChanged();
    }
  }
}

LoginWithFacebook.defaultProps = applyDoNotTrack({
  ...GDPRAwareWidget.defaultProps,
  id: "login-with-facebook",
  assets: [
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/
    {
      as: "script",
      comment: "Login with Facebook",
      source: "https://connect.facebook.net/en_US/sdk.js"
    }
  ],
  type: GDPRAwareWidget.WIDGET_TYPE_URI,
  headless: false,
  lazy: false,
  // https://analytics.google.com/analytics/ > All accounts > select account > UA-xxxxxxx
  identity: null
});

const WithRouterLoginWithFacebook = withRouter(LoginWithFacebook);

const WithSiteLoginWithFacebook = props =>
  withSiteContext(WithRouterLoginWithFacebook)(props);

export const LoginProviderFacebook = (options = {}) => {
  return {
    button: (
      <WithSiteLoginWithFacebook
        className="mx-0 px-0"
        onMount={() => {
          assetsReady = true;
        }}
        assetsReady={assetsReady}
      >
        <BaseButton
          title="Login with Facebook"
          variant="facebook"
          block
          icon={{ icon: ["fab", "facebook-f"] }}
          align={IconAlignLeft}
          onClick={options.onClick}
          disabled={options.disabled}
        />
      </WithSiteLoginWithFacebook>
    ),
    init: () =>
      new Promise((resolve, reject) => {
        if (!oauthClient) {
          window.FB.init({
            appId: "1131603447320393",
            cookie: true,
            xfbml: true,
            version: "v10.0"
          });

          oauthClient = window.FB;
        }

        resolve(oauthClient);
      }),
    login: oauthClient =>
      new Promise((resolve, reject) => {
        oauthClient.getLoginStatus(res => {
          if ("connected" === res.status) {
            console.log("Current user: " + res.authResponse.userID);

            if (!options.alwayseLogin) {
              return resolve(res);
            }
          }

          oauthClient.login(resolve);
        });
      })
  };
};
