// !!! The value of these action types should be unique !!!

// ------------------------- USER REGISTRATION ----------------------------------
export const USER_REGISTRATION = "4i"; // when registering a new user
export const USER_REGISTRATION_REQUEST = "4j"; //user registration request sent
export const USER_REGISTRATION_SUCCESS = "4k"; //user registration request succeeded
export const USER_REGISTRATION_ERROR = "4l"; //user registration request failed

// ------------------------- USER ACTIVATION ----------------------------------
export const USER_ACTIVATION = "4m"; // when activating a new user
export const USER_ACTIVATION_REQUEST = "4n"; //user activation request sent
export const USER_ACTIVATION_SUCCESS = "4o"; //user activation request succeeded
export const USER_ACTIVATION_ERROR = "4p"; //user activation request failed

// ------------------------- USER LOGIN/LOGOUT ----------------------------------
export const USER_LOGIN_ADD_CHANGE_SUBSCRIBER =
  "4q"; // when subscribing a listener to login change events
export const USER_LOGIN_CHANGE_UNSUBSCRIBE = "4r"; // when unsubscribing a listener from the login change events
export const USER_LOGIN = "4s"; // when an user logs in
export const USER_LOGOUT = "4t"; // when an user logs out
export const USER_WELCOME_DISMISS = "4u"; // when an user dismisses welcome message
export const USER_LOGIN_REQUEST = "4v"; //user an user login request sent
export const USER_LOGIN_SUCCESS = "4w"; //user an user login request succeeded
export const USER_LOGIN_ERROR = "4x"; //user an user login request failed

// ------------------------- USER RECOVERY PASSWORD ----------------------------------
export const USER_RECOVERY_PASSWORD = "4y"; // when recovering an user password
export const USER_RECOVERY_PASSWORD_REQUEST = "4z"; //user password recovery request sent
export const USER_RECOVERY_PASSWORD_SUCCESS = "50"; //user password recovery request succeeded
export const USER_RECOVERY_PASSWORD_ERROR = "51"; //user password recovery request failed
export const USER_RECOVERY_PASSWORD_ACKNOWLEDGE =
  "52"; //user password reset response is acknowledged

// ------------------------- USER RESET PASSWORD ----------------------------------
export const USER_RESET_PASSWORD = "53"; // when reseting an user password
export const USER_RESET_PASSWORD_REQUEST = "54"; //user password reset request sent
export const USER_RESET_PASSWORD_SUCCESS = "55"; //user password reset request succeeded
export const USER_RESET_PASSWORD_ERROR = "56"; //user password reset request failed
export const USER_RESET_PASSWORD_ACKNOWLEDGE =
  "57"; //user password reset response is acknowledged

// ------------------------- USER PROFILE ----------------------------------
export const USER_PROFILE_UPDATE = "58"; // when updating the user profile
export const USER_PROFILE_UPDATE_REQUEST = "59"; //user profile update request sent
export const USER_PROFILE_UPDATE_SUCCESS = "5a"; //user profile update request succeeded
export const USER_PROFILE_UPDATE_ERROR = "5b"; //user profile update request failed

// ------------------------- USER ADDRESS ----------------------------------
export const USER_ADDRESS_UPDATE = "5c"; // when updating the user address
export const USER_ADDRESS_UPDATE_REQUEST = "5d"; //user address update request sent
export const USER_ADDRESS_UPDATE_SUCCESS = "5e"; //user address update request succeeded
export const USER_ADDRESS_UPDATE_ERROR = "5f"; //user address update request failed

// ------------------------- USER ORDER LIST ----------------------------------
export const USER_ORDER_LIST = "5g"; // when getting the user order list
export const USER_ORDER_LIST_REQUEST = "5h"; //user order list request sent
export const USER_ORDER_LIST_SUCCESS = "5i"; //user order list request succeeded
export const USER_ORDER_LIST_ERROR = "5j"; //user order list request failed

// ------------------------- USER ORDER LINES ----------------------------------
export const USER_ORDER_DETAILS = "5k"; // when getting the user order lines
export const USER_ORDER_DETAILS_REQUEST = "5l"; //user order lines request sent
export const USER_ORDER_DETAILS_SUCCESS = "5m"; //user order lines request succeeded
export const USER_ORDER_DETAILS_ERROR = "5n"; //user order lines request failed

// ------------------------- USER ORDER REMOVE ----------------------------------
export const USER_ORDER_REMOVE = "5o"; // when removing the user order
export const USER_ORDER_REMOVE_REQUEST = "5p"; //user order lines removal sent
export const USER_ORDER_REMOVE_SUCCESS = "5q"; //user order removal request succeeded
export const USER_ORDER_REMOVE_ERROR = "5r"; //user order removal request failed

// ------------------------- USER {PAYMENT} LIST ----------------------------------
export const USER_PAYMENT_LIST = "5s"; // when getting the user payment list
export const USER_PAYMENT_LIST_REQUEST = "5t"; //user payment list request sent
export const USER_PAYMENT_LIST_SUCCESS = "5u"; //user payment list request succeeded
export const USER_PAYMENT_LIST_ERROR = "5v"; //user payment list request failed
