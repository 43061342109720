import BaseButton from "@components-core/BaseButton";
import RouteLink from "@components-core/RouteLink";
import { PAGE_KEY_INTEGRITY_POLICY } from "@constants";
import {
  userActivation,
  userActivationFailure,
  userActivationSuccess,
  userRegistration,
  userRegistrationFailure,
  userRegistrationSuccess
} from "@redux-actions/user";
import { UserRegistrationBS } from "@style-variables";
import { debug } from "@utils/debug";
import { validPassword } from "@utils/password";
import { escapeReact } from "@utils/react";
import {
  getComponentClassName,
  joinNonEmptyStrings,
  validConfirmPassword,
  validEmail
} from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import UserFormValidation from "./FormValidation";

class UserRegistration extends UserFormValidation {
  /**
   * @inheritdoc
   * @memberof UserRegistration
   */
  static get mapStateToProps() {
    return (state, ownProps) => {
      const user = state[this.prefix];
      const result = user.status
        ? user.status.registerNewUser || user.status.activateUser
        : null;

      return {
        token: ownProps.match.params.token,
        registrationResult: {
          error:
            !user.isFetching &&
            (user.error || (result && result.success === false))
              ? {
                  exists: result && result.exists === true,
                  fetch: user.error
                }
              : null,
          success:
            !user.isFetching && result && result.success === true
              ? {
                  email: result.email
                }
              : null
        }
      };
    };
  }

  /**
   * @inheritdoc
   * @memberof UserRegistration
   */
  static get mapDispatchToProps() {
    return {
      userRegistration,
      userRegistrationSuccess,
      userRegistrationFailure,
      //
      userActivation,
      userActivationSuccess,
      userActivationFailure
    };
  }

  /**
   * @inheritdoc
   * @memberof UserRegistration
   */
  static get mapValueToProps() {
    return value => {
      return {
        ...super.mapValueToProps(value),
        setup: {
          fields: {
            firstName: value.i18n.components.UserRegistration.LABEL_FIRSTNAME,
            lastName: value.i18n.components.UserRegistration.LABEL_LASTNAME,
            userName: value.i18n.components.UserRegistration.LABEL_USERNAME,
            email: value.i18n.components.UserRegistration.LABEL_EMAIL,
            password: value.i18n.components.UserRegistration.LABEL_PASSWORD,
            confirmPassword:
              value.i18n.components.UserRegistration.LABEL_CONFIRM_PASSWORD,
            acceptTerms: (
              <RouteLink
                to={value.pathfinder.generate(PAGE_KEY_INTEGRITY_POLICY)}
              >
                {value.i18n.components.UserRegistration.CHK_ACCEPT_TERMS_POLICY}
              </RouteLink>
            )
          },
          button: value.i18n.components.UserRegistration.BTN_REGISTER
        }
      };
    };
  }

  /**
   * @inheritdoc
   * @memberof UserRegistration
   */
  static get validationRules() {
    return {
      firstName: 2,
      lastName: 3,
      userName: 5,
      password: validPassword,
      confirmPassword: (password, values) =>
        validConfirmPassword(password, values.password),
      email: validEmail,
      acceptTerms: value => (value === true ? true : ["checked"])
    };
  }

  /**
   * @inheritdoc
   * @memberof UserRegistration
   */
  static get prefix() {
    return "userReg"; // defined by reducer
  }

  /**
   * @inheritdoc
   * @memberof UserRegistration
   */
  static get suffix() {
    return "Data";
  }

  constructor(props) {
    super(props);

    this.handleTermsChange = this.handleTermsChange.bind(this);
    this.handleUserRegistration = this.handleUserRegistration.bind(this);
  }

  componentDidMount() {
    if (this.props.token) {
      this.setState({ alert: true });

      this.props
        .userActivation(
          {
            siteId: this.props.siteId,
            token: this.props.token,
            returnPath: this.props.pathfinder.prefixRoute("")
          },
          this.props.siteConfig
        )
        .then(result => this.props.userActivationSuccess(result))
        .catch(error => this.props.userActivationFailure(error));
    }
  }

  /**
   * @description Handle the accept-terms checkbox event
   * @param {Event} e
   * @memberof UserRegistration
   */
  handleTermsChange(e) {
    this.setState({
      [this.getStatic("prefix")]: {
        ...this.state[this.getStatic("prefix")],
        acceptTerms: e.target.checked
      }
    });
  }

  /**
   * @description Handle the register-user button click event
   * @param {Event} e
   * @memberof UserRegistration
   */
  handleUserRegistration(e) {
    try {
      const payload = this.validateObject(
        this.state[this.getStatic("prefix")],
        this.getStatic("prefix")
      );

      this.setState({ alert: true });

      this.props
        .userRegistration(
          {
            siteId: this.props.siteId,
            firstName: payload.firstName,
            lastName: payload.lastName,
            userName: payload.userName,
            email: payload.email,
            password: payload.password,
            returnPath: this.props.pathfinder.prefixRoute("")
          },
          this.props.siteConfig
        )
        .then(result => this.props.userRegistrationSuccess(result))
        .catch(error => this.props.userRegistrationFailure(error));
    } catch (e) {
      debug(e);
    }
  }

  /**
   * @inheritdoc
   * @memberof UserRegistration
   */
  getFormChangeActionProps(e, prefix, name) {
    return {
      [name]: name === "acceptTerms" ? e.target.checked : e.target.value
    };
  }

  /**
   * @inheritdoc
   * @memberof UserRegistration
   */
  getRenderedFields() {
    return this.renderFields(this.getStatic("prefix"));
  }

  /**
   * @inheritdoc
   * @memberof UserRegistration
   */
  renderButtons() {
    return (
      <Row>
        <Col
          className={getComponentClassName(
            UserRegistrationBS,
            "button",
            "text-center"
          )}
        >
          <BaseButton
            variant="primary"
            title={this.props.setup.button}
            onClick={this.handleUserRegistration}
            size="lg"
          />
        </Col>
      </Row>
    );
  }

  /**
   * @inheritdoc
   * @memberof UserRegistration
   */
  getFormFields(prefix) {
    return [
      [
        [
          "firstName",
          { xs: 12, sm: 6, as: Col },
          { required: true, autoComplete: "given-name" }
        ],
        [
          "lastName",
          { xs: 12, sm: 6, as: Col },
          { required: true, autoComplete: "family-name" }
        ]
      ],
      ["userName", null, { required: true, autoComplete: "username" }],
      [
        "email",
        null,
        {
          type: "email",
          maxLength: 254,
          required: true,
          autoComplete: "email"
        }
      ],
      [
        [
          "password",
          { xs: 12, sm: 6, as: Col },
          {
            type: "password",
            required: true,
            autoComplete: "new-password"
          }
        ],
        [
          "confirmPassword",
          { xs: 12, sm: 6, as: Col },
          {
            type: "password",
            required: true,
            autoComplete: "new-password"
          }
        ]
      ],
      ["acceptTerms", null, { type: "checkbox", required: true }]
    ];
  }

  /**
   * @inheritdoc
   * @memberof UserRegistration
   */
  onControlKeyUp(e) {
    this.handleUserRegistration(e);
  }

  /**
   * @description Render a registration success alert
   * @returns {JSX}
   * @memberof UserRegistration
   */
  renderSuccessAlert() {
    const i18n = this.props.i18n.components.UserRegistration;

    const redirect = () => {
      const auth = {
        [this.getStatic("prefix")]: this.props.registrationResult.success.email,
        password: this.state[this.getStatic("prefix")].password
      };

      this.props.history.push(
        this.props.pathfinder
          .generate("loginUser")
          .replace(":hash", btoa(JSON.stringify(auth)))
      );
    };

    const message = (
      this.props.token ? i18n.ACTIVATION_SUCCESS : i18n.REGISTRATION_SUCCESS
    ).replace("%email%", this.props.registrationResult.success.email);

    return this.renderAlert(
      escapeReact(message),
      { variant: "success", onClick: redirect, children: i18n.BTN_LOGIN },
      false
    );
  }

  render() {
    const i18n = this.props.i18n.components.UserRegistration;
    const status = this.props.registrationResult;

    let alert = null;

    if ((status.error || status.success) && this.state.alert) {
      let message = null;

      if (status.error) {
        if (status.error.exists) {
          message = this.props.token
            ? i18n.ACTIVATION_USER_EXISTS
            : i18n.REGISTRATION_USER_EXISTS;
        }
        if (status.error.fetch) {
          message = status.error.fetch;
        }
        alert = this.renderErrorAlert(
          escapeReact(message, this.props.pathfinder, true)
        );
      } else {
        alert = this.renderSuccessAlert(status);
      }
    }

    const form =
      status.success && this.state.alert ? null : (
        <Container className="col-md-6 my-5">
          <Card
            className={getComponentClassName(
              UserRegistrationBS,
              null,
              joinNonEmptyStrings(this.props.className, "m-3", " ")
            )}
          >
            <Card.Header className="font-weight-bold">
              {i18n.FORM_TITLE}
            </Card.Header>
            <Card.Body>{this.getForm()}</Card.Body>
          </Card>
        </Container>
      );

    return (
      <React.Fragment>
        {alert}
        {form}
      </React.Fragment>
    );
  }
}

UserRegistration.propTypes = {
  ...UserFormValidation.propTypes,
  className: PropTypes.string
};

UserRegistration.defaultProps = {
  ...UserFormValidation.defaultProps,
  className: UserRegistrationBS
};

export default UserRegistration.connectHOC;
