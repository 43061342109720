import ModalDialog from "@components-core/ModalDialog";
import PropTypes from "prop-types";
import React from "react";

function RemoveUserOrderModal(props) {
  if (!props.order) {
    return null;
  }

  const buttons = [
    {
      variant: "secondary",
      onClick: e => {
        if ("function" === typeof props.onClose) {
          props.onClose(e, props.order);
        }
      },
      title: "Cancel"
    },
    {
      variant: "danger",
      onClick: e => {
        if ("function" === typeof props.onRemove) {
          props.onRemove(e, props.order);
        }
      },
      title: "Just do it!",
      disabled: !props.canRemove
    }
  ];

  const error = props.order.error;

  const order = error ? props.order.order : props.order;

  const body = error ? (
    <p className="text-danger">{error.message}</p>
  ) : (
    <React.Fragment>
      <p>
        You have chosen to remove the{" "}
        {props.paymentStatusTransformer(order.paymentStatus)} order #
        {order.orderNo}. This is an irreversible action.
      </p>
      <p>Are you sure you want to continue?</p>
    </React.Fragment>
  );

  return (
    <ModalDialog
      show={props.show}
      onHide={props.onClose}
      title={`Remove order #${order.orderNo}`}
      body={body}
      buttons={buttons}
      className="centered"
      centered={false}
      backdrop={true}
    />
  );
}

RemoveUserOrderModal.propTypes = {
  show: PropTypes.bool.isRequired,
  canRemove: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  orderStatusTransformer: PropTypes.func.isRequired,
  paymentStatusTransformer: PropTypes.func.isRequired
};

RemoveUserOrderModal.defaultProps = {
  show: false
};

export default React.memo(RemoveUserOrderModal);
