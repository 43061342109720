import { debug } from "@utils/debug";
import React from "react";
import { IconAlignLeft } from "@style-variables";
import BaseButton from "@components-core/BaseButton";
import GDPRAwareWidget from "../../GDPR/GDPRAwareWidget";
import { applyDoNotTrack } from "../../GDPR/utils";
import { withRouter } from "react-router";
import { withSiteContext } from "@site-context";

let oauthClient = null;
let assetsReady = false;

class LoginWithTwitter extends GDPRAwareWidget {
  onGlobalVariableError() {
    debug(
      `The global variable '?' is not found. This idicates a bug within ${this.constructor.name} component.`,
      "warn"
    );
  }

  /**
   * @inheritdoc
   * @memberof LoginWithTwitter
   */
  onCookieUsageChanged() {
    // https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out
    // window["ga-disable-" + this.props.identity] = this.state.doNotTrack;

    if ("undefined" !== typeof undefined) {
      this.setTrackingOption();
    } else {
      this.onGlobalVariableError();
    }
  }

  setTrackingOption() {
    if (this.props.externalCookieController) {
      return;
    }
  }

  componentDidUpdate() {
    if (this.props.assetsReady) {
      this.onCookieUsageChanged();
    }
  }
}

LoginWithTwitter.defaultProps = applyDoNotTrack({
  ...GDPRAwareWidget.defaultProps,
  id: "login-with-twitter",
  assets: [],
  type: GDPRAwareWidget.WIDGET_TYPE_URI,
  headless: false,
  lazy: false,
  // https://analytics.google.com/analytics/ > All accounts > select account > UA-xxxxxxx
  identity: null
});

const WithRouterLoginWithTwitter = withRouter(LoginWithTwitter);

const WithSiteLoginWithTwitter = props =>
  withSiteContext(WithRouterLoginWithTwitter)(props);

export const LoginProviderTwitter = (options = {}) => {
  return {
    button: (
      <WithSiteLoginWithTwitter
        className="mx-0 px-0"
        onMount={() => {
          assetsReady = true;
        }}
        assetsReady={assetsReady}
      >
        <BaseButton
          title="Login with Twitter"
          variant="twitter"
          block
          icon={{ icon: ["fab", "twitter"] }}
          align={IconAlignLeft}
          onClick={options.onClick}
          disabled={options.disabled}
        />
      </WithSiteLoginWithTwitter>
    ),
    init: () =>
      new Promise((resolve, reject) => {
        resolve(oauthClient);
      }),
    login: oauthClient =>
      new Promise((resolve, reject) => {
        resolve();
      })
  };
};
