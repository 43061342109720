import { validEmail } from "@utils/strings";
import { SITE_SETTINGS_PREVIEW } from "../actionTypes";
import {
  USER_ACTIVATION,
  USER_ACTIVATION_ERROR,
  USER_ACTIVATION_REQUEST,
  USER_ACTIVATION_SUCCESS,
  USER_LOGIN,
  USER_LOGIN_ADD_CHANGE_SUBSCRIBER,
  USER_LOGIN_CHANGE_UNSUBSCRIBE,
  USER_LOGIN_ERROR,
  //
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_WELCOME_DISMISS,
  //
  USER_ORDER_LIST,
  USER_ORDER_LIST_ERROR,
  USER_ORDER_LIST_REQUEST,
  USER_ORDER_LIST_SUCCESS,
  //
  USER_ORDER_DETAILS,
  USER_ORDER_DETAILS_ERROR,
  USER_ORDER_DETAILS_REQUEST,
  USER_ORDER_DETAILS_SUCCESS,
  //
  USER_ORDER_REMOVE,
  USER_ORDER_REMOVE_ERROR,
  USER_ORDER_REMOVE_REQUEST,
  USER_ORDER_REMOVE_SUCCESS,
  //
  USER_PAYMENT_LIST,
  USER_PAYMENT_LIST_ERROR,
  USER_PAYMENT_LIST_REQUEST,
  USER_PAYMENT_LIST_SUCCESS,
  //
  USER_PROFILE_UPDATE,
  USER_PROFILE_UPDATE_ERROR,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  //
  USER_ADDRESS_UPDATE,
  USER_ADDRESS_UPDATE_ERROR,
  USER_ADDRESS_UPDATE_REQUEST,
  USER_ADDRESS_UPDATE_SUCCESS,
  //
  USER_RECOVERY_PASSWORD,
  USER_RECOVERY_PASSWORD_ACKNOWLEDGE,
  USER_RECOVERY_PASSWORD_ERROR,
  USER_RECOVERY_PASSWORD_REQUEST,
  USER_RECOVERY_PASSWORD_SUCCESS,
  USER_REGISTRATION,
  USER_REGISTRATION_ERROR,
  USER_REGISTRATION_REQUEST,
  USER_REGISTRATION_SUCCESS,
  //
  USER_RESET_PASSWORD,
  USER_RESET_PASSWORD_ACKNOWLEDGE,
  USER_RESET_PASSWORD_ERROR,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS
} from "../actionTypesUser";
import { getLoggedUserKey } from "../utils";

/**
 * Used by components/User components
 */

// ------------------------- USER REGISTRATION ----------------------------------
/**
 * @description Requesting registering the user
 * @param {Object} payload The user registration payload
 * @returns {Object} The action
 */
function userRegistrationRequest(payload) {
  return {
    type: USER_REGISTRATION_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully registered user
 * @param {Object} result The user registration result
 * @returns {Object} The action
 */
function userRegistrationSuccess(result) {
  return {
    type: USER_REGISTRATION_SUCCESS,
    result
  };
}

/**
 * @description Notifying the store about failing registering the user
 * @param {Error} error
 * @returns {Object}
 */
function userRegistrationFailure(error) {
  return {
    type: USER_REGISTRATION_ERROR,
    error
  };
}

/**
 * @description Submits the user registration to the server
 * @param {Object} payload The user registration payload
 * @param {Object} { siteId, i18n, graphqlClient }
 * @returns {Object} The action
 */
function userRegistration(payload, { siteId, i18n, graphqlClient }) {
  return (dispatch, getState) => {
    // inform the store we received a user-registration action
    dispatch({
      type: USER_REGISTRATION,
      payload
    });

    // inform the store we are sending a user-registration submission request
    dispatch(userRegistrationRequest(payload));

    // return a promise to the result of requested action
    return graphqlClient.gqlModule(
      import(
        /* webpackChunkName: "site" */ "@graphql-mutation/user/userRegistration.gql"
      ),
      { ...payload }
    );
  };
}

// ------------------------- USER ACTIVATION ----------------------------------
/**
 * @description Requesting activating the user
 * @param {Object} payload The user activation payload
 * @returns {Object} The action
 */
function userActivationRequest(payload) {
  return {
    type: USER_ACTIVATION_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully activated user
 * @param {Object} result The user activation result
 * @returns {Object} The action
 */
function userActivationSuccess(result) {
  return {
    type: USER_ACTIVATION_SUCCESS,
    result
  };
}

/**
 * @description Notifying the store about failing activating the user
 * @param {Error} error
 * @returns {Object}
 */
function userActivationFailure(error) {
  return {
    type: USER_ACTIVATION_ERROR,
    error
  };
}

/**
 * @description Submits the user activation to the server
 * @param {Object} payload The user activation payload
 * @param {Object} { siteId, i18n, graphqlClient }
 * @returns {Object} The action
 */
function userActivation(payload, { siteId, i18n, graphqlClient }) {
  return (dispatch, getState) => {
    // inform the store we received a user-activation action
    dispatch({
      type: USER_ACTIVATION,
      payload
    });

    // inform the store we are sending a user-activation submission request
    dispatch(userActivationRequest(payload));

    // return a promise to the result of requested action
    return graphqlClient.gqlModule(
      import(
        /* webpackChunkName: "site" */ "@graphql-mutation/user/activateUser.gql"
      ),
      { ...payload }
    );
  };
}

// ------------------------- USER LOGIN ----------------------------------
/**
 * @description Add a new listener/subscriber to loginlogout actions.
 * Each listener has the chance to do smth. when the login state changes.
 * @export
 * @param {function} listener
 * @returns {Object} The action
 */
function addUserLoginChangeSubscriber(listener) {
  return {
    type: USER_LOGIN_ADD_CHANGE_SUBSCRIBER,
    listener
  };
}

/**
 * @description Remove a listener/subscriber from the login/logout actions
 * @export
 * @param {function} listener
 * @returns {Object} The action
 */
function removeUserLoginChangeSubscriber(listener) {
  return {
    type: USER_LOGIN_CHANGE_UNSUBSCRIBE,
    listener
  };
}

/**
 * @description Notify the login change subscribers about a login event
 * @param {function} getState The store `getState` function
 * @param {function} dispatch The store `dispatch` function
 * @param {Object|array} args The arguments to pass to the notified listener
 */
function notifyUserLoginChangeSubscribers(getState, dispatch, args) {
  // notify the USER_LOGIN_ADD_CHANGE_SUBSCRIBER subscribers
  getState()
    .userLogin.listeners.filter(Boolean)
    .forEach(listener => listener(args));
}

/**
 * @description Requesting an user login
 * @param {Object} payload The user login payload
 * @returns {Object} The action
 */
function userLoginRequest(payload) {
  return {
    type: USER_LOGIN_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully logged in user
 * @param {Object} result The user login result
 * @returns {Object} The action
 */
function userLoginSuccess(result) {
  return (dispatch, getState) => {
    dispatch({ type: USER_LOGIN_SUCCESS, result });

    // notify the USER_LOGIN_ADD_CHANGE_SUBSCRIBER subscribers
    notifyUserLoginChangeSubscribers(getState, dispatch, {
      type: USER_LOGIN_SUCCESS,
      result
    });
  };
}

/**
 * @description Notifying the store about failing login the user
 * @param {Error} error
 * @returns {Object}
 */
function userLoginFailure(error) {
  return {
    type: USER_LOGIN_ERROR,
    error
  };
}

/**
 * @description Submits the user login to the server
 * @param {Object} payload The user login payload
 * @param {Object} { siteId, i18n, graphqlClient }
 * @returns {Object} The action
 */
function userLogin(payload, { siteId, i18n, graphqlClient }) {
  return (dispatch, getState) => {
    // inform the store we received a user-login action
    dispatch({
      type: USER_LOGIN,
      payload
    });

    let module = null;
    const variables = { siteId };

    const addressFragment = import(
      /* webpackChunkName: "site" */ "@graphql-query/addressFragment.gql"
    );

    // NOTE : the payload.email is alwasy NULL, the userName can be either username|email
    if (validEmail(payload.userName)) {
      module = import(
        /* webpackChunkName: "site" */ "@graphql-mutation/user/loginByEmail.gql"
      );
      variables.email = payload.userName;
      variables.password = payload.password;
    } else if (payload.userName) {
      module = import(
        /* webpackChunkName: "site" */ "@graphql-mutation/user/loginByUsername.gql"
      );
      variables.userName = payload.userName;
      variables.password = payload.password;
    } else {
      module = import(
        /* webpackChunkName: "site" */ "@graphql-mutation/loginAnonymous.gql"
      );
    }

    // inform the store we are sending a user-login request
    dispatch(userLoginRequest(payload));

    // return a promise to the result of requested action
    return graphqlClient.gqlModule([module, addressFragment], { ...variables });
  };
}

/**
 * @description Log out the user
 * @returns {Object} The action
 */
function userLogout() {
  return (dispatch, getState) => {
    dispatch({ type: USER_LOGOUT });

    // clean-up
    dispatch({ type: SITE_SETTINGS_PREVIEW, value: false });

    // notify the USER_LOGIN_ADD_CHANGE_SUBSCRIBER subscribers
    notifyUserLoginChangeSubscribers(getState, dispatch, { type: USER_LOGOUT });
  };
}

/**
 * @description Dismiss user first time login welcome message
 * @returns {Object} The action
 */
function userDismissWelcome() {
  return { type: USER_WELCOME_DISMISS };
}

// ------------------------- USER PASSWORD RECOVERY ----------------------------------
/**
 * @description Requesting the user password recovery
 * @param {Object} payload The user login payload
 * @returns {Object} The action
 */
function userRecoveryPasswordAcknowledge() {
  return {
    type: USER_RECOVERY_PASSWORD_ACKNOWLEDGE
  };
}

/**
 * @description Requesting the user password recovery
 * @param {Object} payload The user login payload
 * @returns {Object} The action
 */
function userRecoveryPasswordRequest(payload) {
  return {
    type: USER_RECOVERY_PASSWORD_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully user password recovery result
 * @param {Object} result The user password recovery result
 * @returns {Object} The action
 */
function userRecoveryPasswordSuccess(result) {
  return {
    type: USER_RECOVERY_PASSWORD_SUCCESS,
    result
  };
}

/**
 * @description Notifying the store about failing recovery the user password
 * @param {Error} error
 * @returns {Object}
 */
function userRecoveryPasswordFailure(error) {
  return {
    type: USER_RECOVERY_PASSWORD_ERROR,
    error
  };
}

/**
 * @description Submits the user password recovery to the server
 * @param {Object} payload The user password recovery payload
 * @param {Object} { siteId, i18n, graphqlClient }
 * @returns {Object} The action
 */
function userRecoveryPassword(payload, { siteId, i18n, graphqlClient }) {
  return (dispatch, getState) => {
    // inform the store we received an user password-recovery action
    dispatch({
      type: USER_RECOVERY_PASSWORD,
      payload
    });

    // inform the store we are sending an user password-recovery request
    dispatch(userRecoveryPasswordRequest(payload));

    // return a promise to the result of requested action
    return graphqlClient.gqlModule(
      import(
        /* webpackChunkName: "site" */ "@graphql-mutation/user/recoveryUserPassword.gql"
      ),
      { ...payload }
    );
  };
}

// ------------------------- USER PASSWORD RESET ----------------------------------
/**
 * @description Requesting the user password reset
 * @param {Object} payload The user login payload
 * @returns {Object} The action
 */
function userResetPasswordAcknowledge() {
  return {
    type: USER_RESET_PASSWORD_ACKNOWLEDGE
  };
}

/**
 * @description Requesting the user password reset
 * @param {Object} payload The user login payload
 * @returns {Object} The action
 */
function userResetPasswordRequest(payload) {
  return {
    type: USER_RESET_PASSWORD_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully user password reset
 * @param {Object} result The user login result
 * @returns {Object} The action
 */
function userResetPasswordSuccess(result) {
  return {
    type: USER_RESET_PASSWORD_SUCCESS,
    result
  };
}

/**
 * @description Notifying the store about failing reseting the user password
 * @param {Error} error
 * @returns {Object}
 */
function userResetPasswordFailure(error) {
  return {
    type: USER_RESET_PASSWORD_ERROR,
    error
  };
}

/**
 * @description Submits the user reset password to the server
 * @param {Object} payload The user password reset payload
 * @param {Object} { siteId, i18n, graphqlClient }
 * @returns {Object} The action
 */
function userResetPassword(payload, { siteId, i18n, graphqlClient }) {
  return (dispatch, getState) => {
    // inform the store we received an user password-reset action
    dispatch({
      type: USER_RESET_PASSWORD,
      payload
    });

    // inform the store we are sending an user password-reset request
    dispatch(userResetPasswordRequest(payload));

    // return a promise to the result of requested action
    return graphqlClient.gqlModule(
      import(
        /* webpackChunkName: "site" */ "@graphql-mutation/user/resetUserPassword.gql"
      ),
      { ...payload }
    );
  };
}

// ------------------------- USER PROFILE UPDATE ----------------------------------
/**
 * @description Requesting the user profile update
 * @param {Object} payload The user profile payload
 * @returns {Object} The action
 */
function userProfileUpdateRequest(payload) {
  return {
    type: USER_PROFILE_UPDATE_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully updated user profile
 * @param {Object} result The user profile update result
 * @returns {Object} The action
 */
function userProfileUpdateSuccess(result) {
  return (dispatch, getState) => {
    dispatch({
      type: USER_PROFILE_UPDATE_SUCCESS,
      result
    });

    // find the method used for logging in
    const state = getState().userLogin;
    const loginMethod = getLoggedUserKey(state);

    // update the current logged user Redux state to reflect the profile change
    if (loginMethod) {
      const updatedFields = result.updateUserProfile
        .map(item => ({ [item.fieldName]: item.newValue }))
        .reduce((carry, item) => Object.assign(carry, item), {});

      const updatedLogin = { ...state.status[loginMethod], ...updatedFields };

      dispatch({
        type: USER_LOGIN_SUCCESS,
        result: { [loginMethod]: updatedLogin }
      });
    }
  };
}

/**
 * @description Notifying the store about failing updating the user profile
 * @param {Error} error
 * @returns {Object}
 */
function userProfileUpdateFailure(error) {
  return {
    type: USER_PROFILE_UPDATE_ERROR,
    error
  };
}

/**
 * @description Submits the user profile update to the server
 * @param {Object} payload The user profile update payload
 * @param {Object} { siteId, i18n, graphqlClient }
 * @returns {Object} The action
 */
function userProfileUpdate(payload, { siteId, i18n, graphqlClient }) {
  return (dispatch, getState) => {
    // inform the store we received an user profile update action
    dispatch({
      type: USER_PROFILE_UPDATE,
      payload
    });

    // inform the store we are sending an user profile update request
    dispatch(userProfileUpdateRequest(payload));

    // return a promise to the result of requested action
    return graphqlClient.gqlModule(
      import(
        /* webpackChunkName: "site" */ "@graphql-mutation/user/updateUserProfile.gql"
      ),
      payload
    );
  };
}

// ------------------------- USER ADDRESS UPDATE ----------------------------------
/**
 * @description Requesting the user address update
 * @param {Object} payload The user address payload
 * @returns {Object} The action
 */
function userAddressUpdateRequest(payload) {
  return {
    type: USER_ADDRESS_UPDATE_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully updated user address
 * @param {Object} result The user address update result
 * @returns {Object} The action
 */
function userAddressUpdateSuccess(result) {
  return (dispatch, getState) => {
    // find the method used for logging in
    const state = getState();
    const loginStatus = state.userLogin;
    const userAddress = { ...state.userAddress.status };

    dispatch({
      type: USER_ADDRESS_UPDATE_SUCCESS,
      result
    });

    if (!result) {
      return;
    }

    const loginMethod = getLoggedUserKey(loginStatus);

    // update the current logged user Redux state to reflect its address change
    if (loginMethod) {
      const deliveryAddress = userAddress.deliveryAddress || {};
      const invoiceAddress = userAddress.invoiceAddress;

      const updatedLogin = {
        ...loginStatus.status[loginMethod],
        deliveryAddress,
        invoiceAddress
      };

      dispatch({
        type: USER_LOGIN_SUCCESS,
        result: { [loginMethod]: updatedLogin }
      });
    }
  };
}

/**
 * @description Notifying the store about failing updating the user address
 * @param {Error} error
 * @returns {Object}
 */
function userAddressUpdateFailure(error) {
  return {
    type: USER_ADDRESS_UPDATE_ERROR,
    error
  };
}

/**
 * @description Submits the user address update to the server
 * @param {Object} payload The user address update payload
 * @param {Object} { siteId, i18n, graphqlClient }
 * @returns {Object} The action
 */
function userAddressUpdate(payload, { siteId, i18n, graphqlClient }) {
  return (dispatch, getState) => {
    // inform the store we received an user address update action
    dispatch({
      type: USER_ADDRESS_UPDATE,
      payload
    });

    // inform the store we are sending an user address update request
    dispatch(userAddressUpdateRequest(payload));

    // return a promise to the result of requested action
    return graphqlClient.gqlModule(
      import(
        /* webpackChunkName: "site" */ "@graphql-mutation/user/updateUserAddress.gql"
      ),
      payload
    );
  };
}

// ------------------------- USER ORDER LIST ----------------------------------
/**
 * @description Requesting the user order list
 * @param {Object} payload The user order list request payload
 * @returns {Object} The action
 */
function userOrderListRequest(payload) {
  return {
    type: USER_ORDER_LIST_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully fetched user order list
 * @param {Object} result The user order list result
 * @returns {Object} The action
 */
function userOrderListSuccess(result) {
  return {
    type: USER_ORDER_LIST_SUCCESS,
    result
  };
}

/**
 * @description Notifying the store about failing fetching the user order list
 * @param {Error} error
 * @returns {Object}
 */
function userOrderListFailure(error) {
  return {
    type: USER_ORDER_LIST_ERROR,
    error
  };
}

/**
 * @description Submits the user order list request to the server
 * @param {Object} payload The user order list request payload
 * @param {Object} { siteId, i18n, graphqlClient }
 * @returns {Object} The action
 */
function userOrderList(payload, { siteId, i18n, graphqlClient }) {
  return (dispatch, getState) => {
    // inform the store we received an user order list action
    dispatch({
      type: USER_ORDER_LIST,
      payload
    });

    // inform the store we are sending an user order list request
    dispatch(userOrderListRequest(payload));

    // return a promise to the result of requested action
    return graphqlClient.gqlModule(
      import(
        /* webpackChunkName: "site" */ "@graphql-mutation/user/userOrderList.gql"
      ),
      { siteId, userId: payload.userId }
    );
  };
}

// ------------------------- USER ORDER DETAILS ----------------------------------
/**
 * @description Requesting the user order details
 * @param {Object} payload The user order details request payload
 * @returns {Object} The action
 */
function userOrderDetailsRequest(payload) {
  return {
    type: USER_ORDER_DETAILS_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully fetched user order details
 * @param {Object} result The user order details result
 * @returns {Object} The action
 */
function userOrderDetailsSuccess(result) {
  return {
    type: USER_ORDER_DETAILS_SUCCESS,
    result
  };
}

/**
 * @description Notifying the store about failing fetching the user order details
 * @param {Error} error
 * @returns {Object}
 */
function userOrderDetailsFailure(error) {
  return {
    type: USER_ORDER_DETAILS_ERROR,
    error
  };
}

/**
 * @description Submits the user order details request to the server
 * @param {Object} payload The user order details request payload
 * @param {Object} { siteId, i18n, graphqlClient }
 * @returns {Object} The action
 */
function userOrderDetails(payload, { siteId, i18n, graphqlClient }) {
  return (dispatch, getState) => {
    // inform the store we received an user order list action
    dispatch({
      type: USER_ORDER_DETAILS,
      payload
    });

    // inform the store we are sending an user order list request
    dispatch(userOrderDetailsRequest(payload));

    // return a promise to the result of requested action
    return graphqlClient.gqlModule(
      import(
        /* webpackChunkName: "site" */ "@graphql-mutation/user/userOrderDetails.gql"
      ),
      { ...payload, siteId }
    );
  };
}

// ------------------------- USER ORDER REMOVE ----------------------------------
/**
 * @description Requesting the user order removal
 * @param {Object} payload The user order removal request payload
 * @returns {Object} The action
 */
function userOrderRemoveRequest(payload) {
  return {
    type: USER_ORDER_REMOVE_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully removal of user order
 * @param {Object} result The user order removal result
 * @returns {Object} The action
 */
function userOrderRemoveSuccess(result) {
  return {
    type: USER_ORDER_REMOVE_SUCCESS,
    result
  };
}

/**
 * @description Notifying the store about failing removing the user order
 * @param {Error} error
 * @returns {Object}
 */
function userOrderRemoveFailure(error) {
  return {
    type: USER_ORDER_REMOVE_ERROR,
    error
  };
}

/**
 * @description Submits the user order removal request to the server
 * @param {Object} payload The user order removal request payload
 * @param {Object} { siteId, i18n, graphqlClient }
 * @returns {Object} The action
 */
function userOrderRemove(payload, { siteId, i18n, graphqlClient }) {
  return (dispatch, getState) => {
    // inform the store we received an user order removal action
    dispatch({
      type: USER_ORDER_REMOVE,
      payload
    });

    // inform the store we are sending an user order removal request
    dispatch(userOrderRemoveRequest(payload));

    // return a promise to the result of requested action
    return graphqlClient.gqlModule(
      import(
        /* webpackChunkName: "site" */ "@graphql-mutation/user/userOrderRemove.gql"
      ),
      { ...payload, siteId }
    );
  };
}

// ------------------------- USER PAYMENT LIST ----------------------------------
/**
 * @description Requesting the user payment list
 * @param {Object} payload The user payment list request payload
 * @returns {Object} The action
 */
function userPaymentListRequest(payload) {
  return {
    type: USER_PAYMENT_LIST_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully fetched user payment list
 * @param {Object} result The user payment list result
 * @returns {Object} The action
 */
function userPaymentListSuccess(result) {
  return {
    type: USER_PAYMENT_LIST_SUCCESS,
    result
  };
}

/**
 * @description Notifying the store about failing fetching the user payment list
 * @param {Error} error
 * @returns {Object}
 */
function userPaymentListFailure(error) {
  return {
    type: USER_PAYMENT_LIST_ERROR,
    error
  };
}

/**
 * @description Submits the user payment list request to the server
 * @param {Object} payload The user payment list request payload
 * @param {Object} { siteId, i18n, graphqlClient }
 * @returns {Object} The action
 */
function userPaymentList(payload, { siteId, i18n, graphqlClient }) {
  return (dispatch, getState) => {
    // inform the store we received an user payment list action
    dispatch({
      type: USER_PAYMENT_LIST,
      payload
    });

    // inform the store we are sending an user payment list request
    dispatch(userPaymentListRequest(payload));

    // return a promise to the result of requested action
    return graphqlClient.gqlModule(
      import(
        /* webpackChunkName: "site" */ "@graphql-mutation/user/userPaymentList.gql"
      ),
      { siteId, userId: payload.userId, orderId: payload.orderId }
    );
  };
}

export {
  userRegistration,
  userRegistrationRequest,
  userRegistrationSuccess,
  userRegistrationFailure,
  //
  userActivation,
  userActivationRequest,
  userActivationSuccess,
  userActivationFailure,
  //
  addUserLoginChangeSubscriber,
  removeUserLoginChangeSubscriber,
  userLogout,
  userDismissWelcome,
  userLogin,
  userLoginRequest,
  userLoginSuccess,
  userLoginFailure,
  //
  userRecoveryPassword,
  userRecoveryPasswordRequest,
  userRecoveryPasswordSuccess,
  userRecoveryPasswordFailure,
  userRecoveryPasswordAcknowledge,
  //
  userResetPassword,
  userResetPasswordRequest,
  userResetPasswordSuccess,
  userResetPasswordFailure,
  userResetPasswordAcknowledge,
  //
  userProfileUpdate,
  userProfileUpdateRequest,
  userProfileUpdateSuccess,
  userProfileUpdateFailure,
  //
  userAddressUpdate,
  userAddressUpdateRequest,
  userAddressUpdateSuccess,
  userAddressUpdateFailure,
  //
  userOrderList,
  userOrderListRequest,
  userOrderListSuccess,
  userOrderListFailure,
  //
  userOrderDetails,
  userOrderDetailsRequest,
  userOrderDetailsSuccess,
  userOrderDetailsFailure,
  //
  userOrderRemove,
  userOrderRemoveRequest,
  userOrderRemoveSuccess,
  userOrderRemoveFailure,
  //
  userPaymentList,
  userPaymentListRequest,
  userPaymentListSuccess,
  userPaymentListFailure
};
