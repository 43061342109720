import passwordValidator from "password-validator";

/**
 * @description Checks whether the given password has a valid pattern
 * @param {String} password
 * @param {boolean} [strong=true] When true enforces strong-password validation rules
 * @param {boolean} [list=true] When true returns the result as a list of unmatched rules, otherwise Boolean
 * @returns {Array|Boolean}
 */
function validPassword(password, strong = true, list = true) {
  const minLength = strong ? 8 : 5;

  var schema = new passwordValidator();

  const passwordRules = schema.is().min(minLength);

  if (strong) {
    const isCommon = ["11111", "12345", "qwerty", "password"].reduce(
      (carry, str) => carry || password.startsWith(str),
      false
    );

    passwordRules
      .is()
      .max(100) // Maximum length 100
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .letters() // Must have letters
      .has()
      .digits() // Must have digits
      .has()
      .symbols() // Must have symbols
      .has()
      .not()
      .spaces() // Should not have spaces
      .is()
      .not()
      .oneOf([isCommon ? password : "", "Passw0rd", "Password123"]); // Blacklist these values
  }

  return passwordRules.validate(password, { list });
}

export { validPassword };
