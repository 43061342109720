import BaseButton from "@components-core/BaseButton";
import { PAGE_KEY_LOGIN } from "@constants";
import {
  userRecoveryPassword,
  userRecoveryPasswordAcknowledge,
  userRecoveryPasswordFailure,
  userRecoveryPasswordSuccess,
  //
  userResetPassword,
  userResetPasswordAcknowledge,
  userResetPasswordFailure,
  userResetPasswordSuccess
} from "@redux-actions/user";
import { UserResetPasswordBS } from "@style-variables";
import { debug } from "@utils/debug";
import { validPassword } from "@utils/password";
import {
  getComponentClassName,
  joinNonEmptyStrings,
  maskEmail,
  validConfirmPassword,
  validEmail
} from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import UserFormValidation from "./FormValidation";

class UserResetPassword extends UserFormValidation {
  static fooPrefix = UserResetPassword.prefix + "Foo";

  /**
   * @inheritdoc
   * @memberof UserResetPassword
   */
  static get mapStateToProps() {
    return (state, ownProps) => {
      const pwdReset = state[this.prefix];

      return {
        token: ownProps.match.params.token,
        resetPasswordResult: {
          error: !pwdReset.isFetching && pwdReset.error ? pwdReset.error : null,
          success:
            !pwdReset.isFetching && pwdReset.status
              ? pwdReset.status.recoveryUserPassword ||
                pwdReset.status.resetUserPassword
              : null
        }
      };
    };
  }

  /**
   * @inheritdoc
   * @memberof UserResetPassword
   */
  static get mapDispatchToProps() {
    return {
      userRecoveryPassword,
      userRecoveryPasswordFailure,
      userRecoveryPasswordSuccess,
      userRecoveryPasswordAcknowledge,
      //
      userResetPassword,
      userResetPasswordFailure,
      userResetPasswordSuccess,
      userResetPasswordAcknowledge
    };
  }

  /**
   * @inheritdoc
   * @memberof UserResetPassword
   */
  static get mapValueToProps() {
    return value => ({
      ...super.mapValueToProps(value),
      setup: {
        fields: {
          email: value.i18n.components.UserResetPassword.LABEL_EMAIL,
          userName: value.i18n.components.UserResetPassword.LABEL_USERNAME,
          newPassword:
            value.i18n.components.UserResetPassword.LABEL_NEW_PASSWORD,
          confirmPassword:
            value.i18n.components.UserResetPassword.LABEL_CONFIRM_PASSWORD
        },
        button: value.i18n.components.UserResetPassword.BTN_RESET_PASSWORD
      }
    });
  }

  /**
   * @inheritdoc
   * @memberof UserResetPassword
   */
  static get validationRules() {
    return {
      userName: userName => `${userName}`.length >= 5 || validEmail(userName),
      email: email => validEmail(email),
      newPassword: validPassword,
      confirmPassword: (password, values) =>
        validConfirmPassword(password, values.newPassword)
    };
  }

  /**
   * @inheritdoc
   * @memberof UserResetPassword
   */
  static get prefix() {
    return "userPwdReset"; // defined by reducer
  }

  /**
   * @inheritdoc
   * @memberof UserResetPassword
   */
  static get suffix() {
    return "Data";
  }

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      [this.getStatic("fooPrefix")]: { userName: true, email: true }
    };

    this.handleUserResetPassword = this.handleUserResetPassword.bind(this);
  }

  /**
   * @description Handle the register-user button click event
   * @param {Event} e
   * @memberof UserResetPassword
   */
  handleUserResetPassword(e) {
    try {
      const payload = this.validateObject(
        this.state[this.getStatic("prefix")],
        this.getStatic("prefix")
      );

      this.setState({ alert: true });

      let action;
      let resolve;
      let reject;

      let args = {
        siteId: this.props.siteId,
        returnPath: this.props.pathfinder.prefixRoute("")
      };

      if (this.props.token) {
        args = {
          ...args,
          token: this.props.token,
          newPassword: payload.newPassword
        };

        action = this.props.userResetPassword;
        resolve = this.props.userResetPasswordSuccess;
        reject = this.props.userResetPasswordFailure;
      } else {
        if (payload.userName) {
          if (validEmail(payload.userName)) {
            args.email = payload.userName;
          } else {
            args.userName = payload.userName;
          }
        } else {
          args.email = payload.email;
        }

        action = this.props.userRecoveryPassword;
        resolve = this.props.userRecoveryPasswordSuccess;
        reject = this.props.userRecoveryPasswordFailure;
      }

      action(args, this.props.siteConfig).then(resolve).catch(reject);

      // initialize the email/username state
      this.setState({
        [this.getStatic("prefix")]: {
          ...this.state[this.getStatic("prefix")],
          email: null,
          token: null
        }
      });
    } catch (e) {
      debug(e);
    }
  }

  /**
   * @inheritdoc
   * @memberof UserResetPassword
   */
  getRenderedFields() {
    return this.renderFields(this.getStatic("prefix"));
  }

  /**
   * @inheritdoc
   * @memberof UserResetPassword
   */
  renderButtons() {
    return (
      <Row>
        <Col
          className={getComponentClassName(
            UserResetPasswordBS,
            "button",
            "text-center"
          )}
        >
          <BaseButton
            variant="primary"
            title={this.props.setup.button}
            onClick={this.handleUserResetPassword}
            size="lg"
          />
        </Col>
      </Row>
    );
  }

  /**
   * @inheritdoc
   * @memberof UserResetPassword
   */
  getFormFields(prefix) {
    // /reset-password/:token
    const isPasswordReset = Boolean(this.props.token);

    if (isPasswordReset) {
      return [
        [
          "newPassword",
          null,
          { type: "password", required: true, autoComplete: "new-password" }
        ],
        [
          "confirmPassword",
          null,
          { type: "password", required: true, autoComplete: "new-password" }
        ]
      ];
    }

    const fooPrefix = this.getStatic("fooPrefix");
    const empty =
      !this.state[fooPrefix].email && !this.state[fooPrefix].userName;

    // recovery-password
    return [
      [
        "userName",
        null,
        {
          required: empty || !this.state[fooPrefix].email,
          //["data-novalidate"]: this.state[fooPrefix].email,
          autoComplete: "username",
          onChange: e =>
            this.setState({
              [fooPrefix]: {
                ...this.state[fooPrefix],
                email: !e.currentTarget.value
              }
            })
        }
      ],
      [
        "email",
        null,
        {
          required: empty || !this.state[fooPrefix].userName,
          //["data-novalidate"]: this.state[fooPrefix].userName,
          autoComplete: "email",
          onChange: e =>
            this.setState({
              [fooPrefix]: {
                ...this.state[fooPrefix],
                userName: !e.currentTarget.value
              }
            })
        }
      ]
    ].filter(([name]) => (this.state[fooPrefix] || {})[name]);
  }

  /**
   * @inheritdoc
   * @memberof UserResetPassword
   */
  onControlKeyUp(e) {
    this.handleUserResetPassword(e);
  }

  render() {
    const i18n = this.props.i18n.components.UserResetPassword;
    const status = this.props.resetPasswordResult;

    let alert = null;

    if (status.error && this.state.alert) {
      const message = this.getErrorStatus(status);

      alert = this.renderErrorAlert(message);
    } else if (status.success) {
      alert = this.renderAlert(
        i18n.W_MAIL_NOTIFICATION.replace(
          "%email%",
          maskEmail(status.success.email)
        ),
        {
          variant: "success",
          onClick: e => {
            this.props.userRecoveryPasswordAcknowledge();
            this.props.history.push(
              this.props.pathfinder.generate(PAGE_KEY_LOGIN)
            );
          },
          children: i18n.BTN_RESET_PASSWORD_ACKNOWLEDGE
        }
      );
    }

    const form = status.success ? null : (
      <Container className="col-md-6 my-5">
        <Card
          className={getComponentClassName(
            UserResetPasswordBS,
            null,
            joinNonEmptyStrings(this.props.className, "m-3", " ")
          )}
        >
          <Card.Header className="font-weight-bold">
            {i18n.FORM_TITLE}
          </Card.Header>
          <Card.Body>{this.getForm()}</Card.Body>
        </Card>
      </Container>
    );

    return (
      <React.Fragment>
        {alert}
        {form}
      </React.Fragment>
    );
  }
}

UserResetPassword.propTypes = {
  ...UserFormValidation.propTypes,
  className: PropTypes.string
};

UserResetPassword.defaultProps = {
  ...UserFormValidation.defaultProps,
  className: UserResetPasswordBS
};

export default UserResetPassword.connectHOC;
