import { PAGE_KEY_USER_REGISTRATION } from "@constants";
import { UserRegistrationBS } from "@style-variables";
import { mergeTemplateItems } from "../common/utils";
import { createLazyTemplateItem } from "../utils";

const TEMPLATE_KEY = "user/RegistrationTemplate";

const UserRegistrationTemplate = props => {
  const defaultTemplate = [
    {
      component: "User/Registration",
      props: {
        className: "bg-light " + UserRegistrationBS
      }
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

UserRegistrationTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_USER_REGISTRATION,
  templateKey: TEMPLATE_KEY
};

export default UserRegistrationTemplate;
