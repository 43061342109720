import {
  USER_LOGIN_REFRESH_TOKEN_FAILURE,
  USER_LOGIN_REFRESH_TOKEN_SUCCESS
} from "../actionTypes";
import {
  //
  USER_ACTIVATION_ERROR,
  USER_ACTIVATION_REQUEST,
  USER_ACTIVATION_SUCCESS,
  //
  USER_LOGIN_ADD_CHANGE_SUBSCRIBER,
  USER_LOGIN_CHANGE_UNSUBSCRIBE,
  USER_LOGIN_ERROR,
  //
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_WELCOME_DISMISS,
  //
  USER_ORDER_LIST_ERROR,
  USER_ORDER_LIST_REQUEST,
  USER_ORDER_LIST_SUCCESS,
  //
  USER_ORDER_DETAILS_ERROR,
  USER_ORDER_DETAILS_REQUEST,
  USER_ORDER_DETAILS_SUCCESS,
  //
  USER_ORDER_REMOVE_ERROR,
  USER_ORDER_REMOVE_REQUEST,
  USER_ORDER_REMOVE_SUCCESS,
  //
  USER_PAYMENT_LIST_ERROR,
  USER_PAYMENT_LIST_REQUEST,
  USER_PAYMENT_LIST_SUCCESS,
  //
  USER_PROFILE_UPDATE_ERROR,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  //
  USER_ADDRESS_UPDATE_ERROR,
  USER_ADDRESS_UPDATE_REQUEST,
  USER_ADDRESS_UPDATE_SUCCESS,
  //
  USER_RECOVERY_PASSWORD_ACKNOWLEDGE,
  USER_RECOVERY_PASSWORD_ERROR,
  USER_RECOVERY_PASSWORD_REQUEST,
  USER_RECOVERY_PASSWORD_SUCCESS,
  //
  USER_REGISTRATION_ERROR,
  USER_REGISTRATION_REQUEST,
  USER_REGISTRATION_SUCCESS,
  //
  USER_RESET_PASSWORD_ACKNOWLEDGE,
  USER_RESET_PASSWORD_ERROR,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS
} from "../actionTypesUser";
import { getLoggedUserKey } from "../utils";

const userReg = (state, action) => {
  const newState = { status: null, error: null, isFetching: false };

  switch (action.type) {
    case USER_ACTIVATION_REQUEST:
    case USER_REGISTRATION_REQUEST:
      return { ...newState, isFetching: true };
    case USER_ACTIVATION_SUCCESS:
    case USER_REGISTRATION_SUCCESS:
      return {
        ...newState,
        status: action.result,
        isFetching: false
      };
    case USER_ACTIVATION_ERROR:
    case USER_REGISTRATION_ERROR:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

const userLogin = (state, action) => {
  const newState = {
    status: null,
    error: null,
    isFetching: false,
    listeners: []
  };

  let stateKey;
  let method;

  switch (action.type) {
    case USER_LOGIN_ADD_CHANGE_SUBSCRIBER:
      if ("function" === typeof action.listener) {
        return {
          ...state,
          listeners: [...state.listeners, action.listener]
        };
      }
      return state;
    case USER_LOGIN_CHANGE_UNSUBSCRIBE:
      if ("function" === typeof action.listener) {
        const index = state.listeners.findIndex(
          listener => listener === action.listener
        );
        if (index > -1) {
          return {
            ...state,
            listeners: [
              ...state.listeners.slice(0, index),
              ...state.listeners.slice(index + 1)
            ]
          };
        }
      }
      return state;

    case USER_LOGIN_REFRESH_TOKEN_SUCCESS:
      stateKey = getLoggedUserKey(state);

      method = Object.keys(action.payload).pop();

      if (stateKey !== method && method !== "refreshToken") {
        return {
          ...state,
          status: action.payload
        };
      }

      return {
        ...state,
        status: {
          ...state.status,
          [stateKey]: {
            ...state.status[stateKey],
            ...action.payload[method]
          }
        }
      };
    case USER_LOGIN_REFRESH_TOKEN_FAILURE:
      return state;
    case USER_LOGOUT:
      return { ...newState };
    case USER_WELCOME_DISMISS:
      stateKey = getLoggedUserKey(state);

      return {
        ...state,
        status: {
          ...state.status,
          [stateKey]: {
            ...state.status[stateKey],
            isFirstTimeLogin: false
          }
        }
      };
    case USER_LOGIN_REQUEST:
      return { ...newState, isFetching: true };
    case USER_LOGIN_SUCCESS:
      return {
        ...newState,
        status: action.result,
        isFetching: false
      };
    case USER_LOGIN_ERROR:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

const userPwdReset = (state, action) => {
  const newState = { status: null, error: null, isFetching: false };

  switch (action.type) {
    case USER_RECOVERY_PASSWORD_ACKNOWLEDGE:
    case USER_RESET_PASSWORD_ACKNOWLEDGE:
      return { ...newState };
    case USER_RECOVERY_PASSWORD_REQUEST:
    case USER_RESET_PASSWORD_REQUEST:
      return { ...newState, isFetching: true };
    case USER_RECOVERY_PASSWORD_SUCCESS:
    case USER_RESET_PASSWORD_SUCCESS:
      return {
        ...newState,
        status: action.result,
        isFetching: false
      };
    case USER_RECOVERY_PASSWORD_ERROR:
    case USER_RESET_PASSWORD_ERROR:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

const userProfile = (state, action) => {
  const newState = { status: null, error: null, isFetching: false };

  switch (action.type) {
    case USER_PROFILE_UPDATE_REQUEST:
      return { ...newState, isFetching: true };
    case USER_PROFILE_UPDATE_SUCCESS:
      return {
        ...newState,
        status: action.result,
        isFetching: false
      };
    case USER_PROFILE_UPDATE_ERROR:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

const userAddress = (state, action) => {
  const newState = { status: null, error: null, isFetching: false };

  switch (action.type) {
    case USER_ADDRESS_UPDATE_REQUEST:
      return {
        ...newState,
        status: {
          deliveryAddress: action.payload.deliveryAddress,
          invoiceAddress: action.payload.altInvoiceAddress
            ? action.payload.invoiceAddress
            : {}
        },
        isFetching: true
      };
    case USER_ADDRESS_UPDATE_SUCCESS:
      return {
        ...newState,
        status: action.result,
        isFetching: false
      };
    case USER_ADDRESS_UPDATE_ERROR:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

const userOrderList = (state, action) => {
  const newState = { status: null, error: null, isFetching: false };

  switch (action.type) {
    case USER_ORDER_LIST_REQUEST:
      return { ...newState, isFetching: true };
    case USER_ORDER_LIST_SUCCESS:
      return {
        ...newState,
        status: action.result,
        isFetching: false
      };
    case USER_ORDER_LIST_ERROR:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

const userOrderDetails = (state, action) => {
  const newState = { status: null, error: null, isFetching: false };

  switch (action.type) {
    case USER_ORDER_DETAILS_REQUEST:
      return { ...newState, isFetching: true };
    case USER_ORDER_DETAILS_SUCCESS:
      return {
        ...newState,
        status: action.result,
        isFetching: false
      };
    case USER_ORDER_DETAILS_ERROR:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

const userOrderRemove = (state, action) => {
  const newState = { status: null, error: null, isFetching: false };

  switch (action.type) {
    case USER_ORDER_REMOVE_REQUEST:
      return { ...newState, isFetching: true };
    case USER_ORDER_REMOVE_SUCCESS:
      return {
        ...newState,
        status: action.result,
        isFetching: false
      };
    case USER_ORDER_REMOVE_ERROR:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

const userPaymentList = (state, action) => {
  const newState = { status: null, error: null, isFetching: false };

  switch (action.type) {
    case USER_PAYMENT_LIST_REQUEST:
      return { ...newState, isFetching: true };
    case USER_PAYMENT_LIST_SUCCESS:
      return {
        ...newState,
        status: action.result,
        isFetching: false
      };
    case USER_PAYMENT_LIST_ERROR:
      return { ...newState, error: action.error.message };
    default:
      return state || newState;
  }
};

export default {
  userReg,
  userLogin,
  userPwdReset,
  userProfile,
  userAddress,
  userOrderList,
  userOrderDetails,
  userOrderRemove,
  userPaymentList
};
