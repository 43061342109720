import React from "react";
import { withRouter } from "react-router";
import { withSiteContext } from "@site-context";
import { IconAlignLeft } from "@style-variables";
import { debug } from "@utils/debug";
import BaseButton from "@components-core/BaseButton";
import GDPRAwareWidget from "../../GDPR/GDPRAwareWidget";
import { applyDoNotTrack } from "../../GDPR/utils";

let oauthClient = null;
let assetsReady = false;

class LoginWithGoogle extends GDPRAwareWidget {
  onGlobalVariableError() {
    debug(
      `The global variable 'gapi' is not found. This idicates a bug within ${this.constructor.name} component.`,
      "warn"
    );
  }

  /**
   * @inheritdoc
   * @memberof LoginWithGoogle
   */
  onCookieUsageChanged() {
    // https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out
    // window["ga-disable-" + this.props.identity] = this.state.doNotTrack;

    if ("undefined" !== typeof window.gapi) {
      this.setTrackingOption();
    } else {
      this.onGlobalVariableError();
    }
  }

  setTrackingOption() {
    if (this.props.externalCookieController) {
      return;
    }
  }

  componentDidUpdate() {
    if (this.props.assetsReady) {
      this.onCookieUsageChanged();
    }
  }
}

LoginWithGoogle.defaultProps = applyDoNotTrack({
  ...GDPRAwareWidget.defaultProps,
  id: "login-with-google",
  assets: [
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/
    {
      as: "script",
      comment: "Login with Google",
      source: "https://apis.google.com/js/api:client.js"
    }
  ],
  type: GDPRAwareWidget.WIDGET_TYPE_URI,
  headless: false,
  lazy: false,
  // https://analytics.google.com/analytics/ > All accounts > select account > UA-xxxxxxx
  identity: null
});

const WithRouterLoginWithGoogle = withRouter(LoginWithGoogle);

const WithSiteLoginWithFacebook = props =>
  withSiteContext(WithRouterLoginWithGoogle)(props);

export const LoginProviderGoogle = (options = {}) => {
  return {
    button: (
      <WithSiteLoginWithFacebook
        className="mx-0 px-0"
        onMount={() => {
          assetsReady = true;
        }}
        assetsReady={assetsReady}
      >
        <BaseButton
          title="Login with Google"
          variant="google"
          block
          icon={{ icon: ["fab", "google"] }}
          align={IconAlignLeft}
          onClick={options.onClick}
          disabled={options.disabled}
        />
      </WithSiteLoginWithFacebook>
    ),
    init: () =>
      new Promise((resolve, reject) => {
        if (oauthClient) {
          resolve(oauthClient);
        } else {
          window.gapi.load("auth2", () => {
            // Retrieve the singleton for the GoogleAuth library and set up the client.
            oauthClient = window.gapi.auth2.init({
              client_id:
                "769470709540-41ttqej21lt6rb1mneef47s6aqgf1plu.apps.googleusercontent.com",
              cookiepolicy: "single_host_origin"
            });

            resolve(oauthClient);
          });
        }
      }),
    login: oauthClient =>
      new Promise((resolve, reject) => {
        if (oauthClient.isSignedIn.get()) {
          console.log(
            "Current user: " +
              oauthClient.currentUser.get().getBasicProfile().getEmail()
          );

          oauthClient.currentUser.listen(googleUser => {
            console.log(
              "Google user changed to: " +
                googleUser.getBasicProfile().getEmail()
            );
          });

          if (!options.alwayseLogin) {
            return resolve(oauthClient.currentUser.get());
          }
        }

        oauthClient
          .signIn({
            scope: "profile email"
          })
          .then(googleUser => {
            const id_token = googleUser.getAuthResponse().id_token;
            resolve(id_token);
            // TODO: define a redux action for loginByProvider with the the id_token; on success/error resolve/reject the promise
            // see: https://developers.google.com/identity/sign-in/web/backend-auth
          })
          .catch(reject);
      })
  };
};
