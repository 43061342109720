import { LoginProviderEmail } from "./Email";
import { LoginProviderFacebook } from "./Facebook";
import { LoginProviderGoogle } from "./Google";
import { LoginProviderTwitter } from "./Twitter";

const LOGIN_PROVIDER_EMAIL = "email";
const LOGIN_PROVIDER_FACEBOOK = "facebook";
const LOGIN_PROVIDER_GOOGLE = "google";
const LOGIN_PROVIDER_TWITTER = "twitter";

// when false then supports only EMAIL login
const OAUTH_LOGIN_SUPPORT = false;

const defaultLoginProviders = [
  {
    type: LOGIN_PROVIDER_EMAIL,
    component: LoginProviderEmail,
    default: true,
    priority: 0
  }
];

const oauthLoginProviders = [];

if (OAUTH_LOGIN_SUPPORT) {
  oauthLoginProviders.push(
    {
      type: LOGIN_PROVIDER_GOOGLE,
      component: LoginProviderGoogle,
      priority: 1
    },
    {
      type: LOGIN_PROVIDER_TWITTER,
      component: LoginProviderTwitter,
      priority: 2
    },
    {
      type: LOGIN_PROVIDER_FACEBOOK,
      component: LoginProviderFacebook,
      priority: 3
    }
  );
}

const loginProviders = [...defaultLoginProviders, ...oauthLoginProviders];

export {
  loginProviders,
  LOGIN_PROVIDER_EMAIL,
  LOGIN_PROVIDER_FACEBOOK,
  LOGIN_PROVIDER_GOOGLE,
  LOGIN_PROVIDER_TWITTER
};
