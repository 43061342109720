import ModalDialog from "@components-core/ModalDialog";
import RouteLink from "@components-core/RouteLink";
import { connectHOCs } from "@components-utils";
import SiteLogo from "@components/SiteLogo/SiteLogo";
import { PAGE_KEY_PRODUCT } from "@constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserOrderDetailsBS } from "@style-variables";
import { timestampToYmdHis } from "@utils/date";
import { printElement } from "@utils/dom";
import {
  formatCurrency,
  getComponentClassName,
  stringToSlug
} from "@utils/strings";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";

const formatOrderLine = (line, i, className, pathfinder) => (
  <Row key={i} className={["order-line", className].filter(Boolean).join(" ")}>
    <Col className="product-id">{line.productId}</Col>
    <Col className="description">
      {[
        line.optionType ? (
          <span key={0} className="option-type">
            {line.optionType}
          </span>
        ) : null,
        line.optionType ? (
          line.description
        ) : line.productId ? (
          <RouteLink
            to={pathfinder.generate(PAGE_KEY_PRODUCT, {
              productId: stringToSlug(line.description) + "-" + line.productId
            })}
            title={line.description}
            target="_blank"
          >
            {line.description}
          </RouteLink>
        ) : (
          line.description
        )
      ].filter(Boolean)}
    </Col>
    <Col className="qty">{line.qty}</Col>
    <Col className="price-with-vat">
      {formatCurrency(line.unitPriceWithVAT)}
    </Col>
    <Col className="vat-amount">{formatCurrency(line.vatAmount)}</Col>
    <Col className="gross-amount">{formatCurrency(line.grossAmount)}</Col>
  </Row>
);

function ViewUserOrderModal(props) {
  const previewRef = useRef(null);

  if (
    !(
      props.order &&
      props.order.orderDetails &&
      props.order.orderDetails.header
    )
  ) {
    return null;
  }

  const formatCurrencyCode = str =>
    [str, `(${props.order.currencyCode})`].filter(Boolean).join(" ");

  const order = props.order.orderDetails || {};
  const header = order.header;
  const deliveryAddress = header.deliveryAddress;
  const invoiceAddress = header.invoiceAddress;

  const shippingMethod = order.lines
    .filter(line => "Fraktsätt" === line.optionType)
    .map(line => line.description)[0];

  const grandTotal = order.lines
    .filter(line => !line.optionType || line.netAmount)
    .reduce(
      (carry, line) =>
        Object.keys(carry).reduce(
          (c, k) => Object.assign(c, { [k]: carry[k] + line[k] }),
          {}
        ),
      { qty: 0, vatAmount: 0, grossAmount: 0 }
    );

  const orderRows = [
    {
      productId: "ID",
      description: "Benämning",
      qty: "Antal",
      unitPriceWithVAT: formatCurrencyCode("A pris"),
      vatAmount: formatCurrencyCode(`Moms`),
      grossAmount: formatCurrencyCode("Summa")
    },
    ...(order.lines || []).filter(line => !line.optionType)
  ].map((line, i) => formatOrderLine(line, i, null, props.pathfinder));

  orderRows.push(
    ...(order.lines || [])
      .filter(line => line.optionType && line.netAmount)
      .map((line, i) => formatOrderLine(line, orderRows.length + i, "option"))
  );

  orderRows.push(
    formatOrderLine(
      {
        productId: null,
        description: "GRAND TOTAL",
        qty: grandTotal.qty,
        unitPriceWithVAT: null,
        vatAmount: grandTotal.vatAmount,
        grossAmount: grandTotal.grossAmount
      },
      orderRows.length,
      "grand-total"
    )
  );

  const supplierOrder = (
    <div className="supplier-order">
      <Row>
        <Col>Order Id</Col>
        <Col>{header.orderId}</Col>
      </Row>
      <Row>
        <Col>Order No.</Col>
        <Col>{header.orderNo}</Col>
      </Row>
      <Row>
        <Col>Order date</Col>
        <Col>{timestampToYmdHis(header.orderDate)}</Col>
      </Row>
      <Row>
        <Col>Payment method</Col>
        <Col>{header.paymentMethod}</Col>
      </Row>
      <Row>
        <Col>Shipping method</Col>
        <Col>{shippingMethod}</Col>
      </Row>
    </div>
  );

  const custmerAddress = title => (
    <div className="customer-address">
      <Row>
        <Col>{title}</Col>
      </Row>
      <Row>
        <Col>{deliveryAddress.co}</Col>
      </Row>
      <Row>
        <Col>{deliveryAddress.address1}</Col>
      </Row>
      <Row>
        <Col>{deliveryAddress.address2}</Col>
      </Row>
      <Row>
        <Col>
          {deliveryAddress.postalCode}, {deliveryAddress.city}
        </Col>
      </Row>
      <Row>
        <Col>{deliveryAddress.country}</Col>
      </Row>
      <Row>
        <Col>{header.phone}</Col>
      </Row>
      <Row>
        <Col>{header.email}</Col>
      </Row>
    </div>
  );

  const custmerAltAddress = title =>
    invoiceAddress ? (
      <div className="customer-alt-address">
        <Row>
          <Col>{title}</Col>
        </Row>
        <Row>
          <Col>{invoiceAddress.co}</Col>
        </Row>
        <Row>
          <Col>{invoiceAddress.address1}</Col>
        </Row>
        <Row>
          <Col>{invoiceAddress.address2}</Col>
        </Row>
        <Row>
          <Col>
            {invoiceAddress.postalCode}, {invoiceAddress.city}
          </Col>
        </Row>
      </div>
    ) : (
      custmerAddress(title)
    );

  const orderGroups = {
    supplierInfo: (
      <Row className="supplier-info">
        <Col className="logo">
          <SiteLogo />
        </Col>
        <Col className="sales-order">{supplierOrder}</Col>
      </Row>
    ),
    customerInfo: (
      <Row className="customer-info">
        <Col className="delivery">{custmerAddress("Shipping to")}</Col>
        <Col className="invoice">{custmerAltAddress("Sell-to address")}</Col>
      </Row>
    ),
    items: (
      <Row className="order-items">
        <Col>
          <div>{orderRows}</div>
        </Col>
      </Row>
    )
  };

  const orderContent = Object.keys(orderGroups).map((key, i) => (
    <React.Fragment key={i}>{orderGroups[key]}</React.Fragment>
  ));

  const body = (
    <Container>
      <div
        ref={previewRef}
        className={getComponentClassName(UserOrderDetailsBS)}
      >
        {orderContent}
      </div>
    </Container>
  );

  const buttons = [
    {
      variant: "secondary",
      onClick: e => {
        if ("function" === typeof props.onClose) {
          props.onClose(e, props.order);
        }
      },
      title: "Cancel"
    },
    {
      variant: "primary",
      onClick: e => {
        if ("function" === typeof props.onPrint) {
          props.onPrint(e, props.order);

          if (previewRef.current) {
            printElement(previewRef.current, "order-" + header.orderNo);
          }
        }
      },
      title: <FontAwesomeIcon icon="print" key={0} />,
      className: "d-none d-md-block"
    }
  ];

  return (
    <ModalDialog
      show={props.show}
      onHide={props.onClose}
      title={`View order #${header.orderId}`}
      body={body}
      buttons={buttons}
      dialogClassName="maximized"
      centered={props.centered}
      backdrop={true}
      size="xl"
    />
  );
}

ViewUserOrderModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  orderStatusTransformer: PropTypes.func.isRequired,
  paymentStatusTransformer: PropTypes.func.isRequired,
  centered: PropTypes.bool
};

ViewUserOrderModal.defaultProps = {
  show: false
};

export default connectHOCs(ViewUserOrderModal, {
  withSite: true
});
