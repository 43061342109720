import { PAGE_KEY_USER_PROFILE } from "@constants";
import { UserProfileBS } from "@style-variables";
import { mergeTemplateItems } from "../common/utils";
import { createLazyTemplateItem } from "../utils";

const TEMPLATE_KEY = "user/ProfileTemplate";

const UserProfileTemplate = props => {
  const defaultTemplate = [
    {
      component: "User/Profile",
      props: {
        className: "bg-light " + UserProfileBS
      }
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

UserProfileTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_USER_PROFILE,
  templateKey: TEMPLATE_KEY
};

export default UserProfileTemplate;
