import React from "react";
import { IconAlignLeft } from "@style-variables";
import BaseButton from "@components-core/BaseButton";

export const LoginProviderEmail = (options = {}) => {
  return {
    button: (
      <BaseButton
        title="Login with e-mail"
        variant="secondary"
        block
        icon={{ icon: "envelope" }}
        align={IconAlignLeft}
        onClick={options.onClick}
        disabled={options.disabled}
      />
    )
  };
};
